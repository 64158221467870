.vacation-mode-hero-banner {
  width: 100vw;
}

.vacation-mode-message {
  white-space: pre-wrap;
  color: var(--deepBlue);
  font-family: "Roboto", sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.15;
  text-align: center;
  margin: 3rem 1rem 5rem;
}

@media screen and (min-width: 900px) {
  .vacation-mode-message {
    margin: 5rem auto;
    width: 80%;
  }
}
