.singleClothingComponentWrapper {
  display: flex;
  flex-direction: column;
}

.clothingDetailsFlexContainer {
  display: flex;
  flex-direction: column;
}

.singleClothing {
  margin: 2rem auto 0;
  width: 18rem;
  height: 18rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: black;
  box-shadow: 5px 5px 3px #888888;
  cursor: pointer;
}

.clothingAndCandidClothingWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-width: 18rem;
  margin: 1rem auto 0;
}

.smallSingleClothing,
.smallCandidClothing {
  width: 7rem;
  height: 7rem;
  background-size: cover;
  background-color: black;
  cursor: pointer;
}

.singleImageName {
  margin: 2rem auto 2rem;
  /* font-family: "Averia Serif Libre", cursive; */
  color: var(--deepBlue);
  text-align: center;
}

.singleClothingPrintName {
  font-style: italic;
  font-size: 2rem;
  margin: 2rem 1rem 2rem;
}

.singleClothingDescName {
  font-style: normal;
}

.singleClothingPrice {
  margin: 2rem;
  text-align: center;
  font-size: 1.5rem;
}

/*
    Select Size Button
*/

.dropbtn {
  background-color: white;
  border: solid 2px black;
  padding: 8px;
  font-size: 1.25rem;
  font-weight: 600;
  min-width: 10rem;
}

.isCenteredDropdown {
  display: flex;
  width: 100%;
}

.sizeDropdown {
  position: relative;
  display: inline-block;
  margin: 0 auto 1rem;
}

.dropdownContent {
  position: absolute;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 10rem;
  cursor: pointer;
}

.priceList {
  width: inherit;
}

.priceList li {
  padding: 8px;
  width: 9rem;
  background-color: black;
  color: white;
}

.priceList li:hover {
  background-color: rgb(43, 244, 223);
  color: black;
  font-weight: bold;
}

.sizeDropdown:hover .dropbtn {
  background-color: rgb(222, 240, 250);
}

/* 
    End Select Size Button
*/

/* 
    Quantity Selector
*/

.clothingQuantityCounter {
  display: flex;
  flex-direction: row;
  margin: 2rem auto 3rem;
  justify-content: center;
}

.decreaseclothingQuantityBtn,
.increaseclothingQuantityBtn {
  width: 2rem;
  height: 2rem;
  border: solid 1px black;
  background-color: black;
  color: white;
  font-size: 1rem;
  font-weight: 900;
}

.clothingQuantityInput {
  max-width: 3rem;
  font-size: 1rem;
  font-weight: bolder;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-align: center;
  border-radius: 0;
}
/* 
    End Quantity Selector
*/
.singleClothingDesc {
  color: var(--deepBlue);
  font-family: "Roboto", sans-serif;
  margin: 0 9vw 3rem;
  line-height: 1.5;
}

.clothing-disclaimer-wrapper {
  max-width: 80vw;
  margin: 0 9vw 2rem;
}

.clothing-disclaimer {
  color: var(--warmOrange);
  font-size: 1.15rem;
}

.addToCartBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem auto 2rem;
  width: 80vw;
  line-height: 2;
  color: white;
  padding: 5px;
  cursor: pointer;
  background-color: var(--brightYellow) !important;
  color: var(--deepBlue) !important;
  border: none !important;
}

.addToCartBtn:hover {
  background-color: white;
  color: black;
}

.addToCartBtn:active {
  background-color: white;
  color: black;
  border: solid 2px black;
}

.returnToHomeBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem auto 5rem;
  width: 80vw;
  line-height: 2;
  border: solid 2px black;
  padding: 5px;
  cursor: pointer;
}

.returnToHomeBtn:hover {
  background-color: gray;
  color: white;
}

.returnToHomeBtn:active {
  background-color: gray;
  color: white;
}

@media screen and (min-width: 900px) {
  .clothingDetailsFlexContainer {
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    margin: 0 auto 3rem;
  }

  .singleClothing {
    width: 25rem;
    height: 25rem;
  }

  .singleClothing:hover {
    transform: scale(1.015);
  }

  .clothingImages {
    width: 130%;
  }

  .clothingLogistics {
    width: 70%;
  }

  .singleImageName {
    text-align: start;
  }

  .singleClothingPrintName {
    margin: 2rem 2rem 2rem 0;
  }

  .singleClothingPrice {
    font-weight: 400;
  }

  .singleClothingDesc {
    /* text-align: center; */
  }

  .addToCartBtn {
    margin: 1rem 0 0;
    width: 15rem;
  }
}

@media screen and (min-width: 1200px) {
  .singleClothingPrintName {
    margin-right: 8rem;
  }
}
