.commissions-header {
  margin: 2rem;
}

.commissions-header-text {
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
  margin-bottom: 1.5rem;
}

.commissions-paragraph {
  font-family: "Roboto", sans-serif;
  color: var(--deepBlue);
  font-size: 1.25rem;
  line-height: 1.15;
  margin-bottom: 1rem;
}

.commissions-strong-paragraph {
  color: var(--warmOrange);
}

.commissions-price-guide-wrapper {
  margin: 2rem auto;
}

.commissions-price-guide-image {
  max-width: 100%;
  width: 100%;
  cursor: pointer;
}

.commissions-price-guide-image:hover {
  transform: scale(1.01);
}

.redirect-to-commissions-form-btn {
  font-family: "Roboto", sans-serif;
  background-color: var(--deepBlue);
  color: var(--standardWhite);
  line-height: 2;
  padding: 5px;
  text-align: center;
  cursor: pointer;
  width: inherit;
  margin: 3rem auto;
  position: relative;
  z-index: 1;
}

.redirect-to-commissions-form-btn:hover {
  color: var(--brightYellow);
}

@media screen and (min-width: 800px) {
  .commissions-header {
    max-width: 80vw;
    margin-left: auto;
    margin-right: auto;
  }
}
