.mostRecentLinksSection {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 3rem 1rem 0;
}

.recentPrintsWrapper, .recentClothingWrapper, .recentMerchItemWrapper, .commissionsWrapper {
    margin-bottom: 1rem;
    width: 50%;
}

.mostRecentLinksImage {
    margin: auto;
    width: 8rem;
    height: 8rem;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
}

.mostRecentLinkName {
    font-family: 'Averia Serif Libre', cursive;
    color: var(--deepBlue);
    text-align: center;
    margin: .5rem auto;
    font-size: 1rem;
}

.homeWelcomeSection {
    margin: 3rem 1.5rem 2rem;
    color: var(--deepBlue);
}

.homeWelcome {
    font-family: 'Averia Serif Libre', cursive;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.homeDescRow1, .homeDescRow2, .homeDescRow3 {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1;
    margin-bottom: 1rem;
}

.welcomeLink:hover {
    color: var(--warmOrange);
    cursor: pointer;
}

@media screen and (min-width: 400px) {
    .mostRecentLinksImage {
        width: 10rem;
        height: 10rem;
    }

    .mostRecentLinkName { font-size: 1rem; }

    .homeWelcome { font-size: 2rem; }

    .homeDescRow1, .homeDescRow2, .homeDescRow3 { font-size: 1.25rem; }
}

@media screen and (min-width: 600) {
    .recentPrintsWrapper, .recentClothingWrapper, .recentMerchItemWrapper, .commissionsWrapper {
        width: 33.33%;
    }
}

@media screen and (min-width: 750px) {
    .recentPrintsWrapper, .recentClothingWrapper, .recentMerchItemWrapper, .commissionsWrapper {
        width: 25%;
    }
}

@media screen and (min-width: 900px) {
    .mostRecentLinksSection {
        margin: 3rem 2rem 0;
    }

    .mostRecentLinksImage {
        width: 12rem;
        height: 12rem;
        /* box-shadow: 5px 5px 3px #888888; */
    }

    .mostRecentLinkName { font-size: 1.25rem; }

    .homeWelcomeSection {
        margin: 5rem 0rem;
        text-align: center;
    }
}

@media screen and (min-width: 1200px) {
    .mostRecentLinksSection {
        justify-content: space-evenly;
    }

    .mostRecentLinksImage {
        width: 15rem;
        height: 15rem;
    }

    .recentPrintsWrapper, .recentClothingWrapper, .recentMerchItemWrapper, .commissionsWrapper {
        width: 20%;
    }
}