.createPrintSection {
  margin: 2rem 2rem 10rem;
}

.createPrintForm {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}

.createPrintForm h1 {
  margin: 2rem 0 1rem;
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
}

.createPrintForm h2 {
  margin: 3rem 0 -1rem;
  font-family: "Averia Serif Libre", cursive;
  font-size: 1.75rem;
  color: var(--deepBlue);
}

.createPrintForm h3 {
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
  font-size: 1rem;
  margin-top: 2rem;
  margin-bottom: 0.25rem;
}

.createPrintForm input {
  max-width: 15rem;
}

.createPrintDescInput {
  max-width: 15rem;
  min-height: 10rem;
}

.createPrintDimensionsCheckboxWrapper {
  display: flex;
  flex-direction: row;
}

.createPrintDimensionsCheckboxWrapper div {
  display: flex;
  justify-content: center;
  margin-right: 2rem;
}

.createPrintDimensionsCheckboxWrapper label {
  font-family: "Roboto", sans-serif;
  color: var(--deepBlue);
  font-size: 1rem;
}

.successfulUploadDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
}

.uploadBtn {
  max-width: 15rem;
  background-color: inherit;
  font-family: "Roboto", sans-serif;
  color: var(--deepBlue);
  font-weight: 600;
  border: solid 1px var(--deepBlue);
  padding: 5px;
  cursor: pointer;
  margin-bottom: 1rem;
}

.uploadBtn:hover {
  background-color: rgb(232, 249, 255);
}

.successfulUploadDiv {
  margin-top: 2rem;
}

.successIcon {
  max-width: 20px;
  max-height: 20px;
}

.successfulUploadDiv h4 {
  font-size: 1rem;
  font-weight: 400;
  margin-left: 0.5rem;
}

.successfulUploadDiv span {
  font-weight: 500;
}

.submitBtn {
  margin-top: 4rem;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  background-color: var(--deepBlue);
  color: var(--standardWhite);
  border: solid 1px var(--deepBlue);
  max-width: 15rem;
  padding: 8px;
  cursor: pointer;
}

.submitBtn:hover {
  background-color: var(--hoverDeepBlue);
}

@media screen and (min-width: 400px) {
  .createPrintForm {
    max-width: 20rem;
    margin: auto;
  }
}
