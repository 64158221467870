.singleItemEditWrapper {
  margin-bottom: 5rem;
}

.editTextarea {
  resize: none;
  max-width: 15rem;
  max-height: 1.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  padding-left: 0.5rem;
  font-family: var(--paragraphFont);
  color: var(--deepBlue);
}
