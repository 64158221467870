.about-header {
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
  font-size: 2rem;
  margin: 0 1.5rem 2rem;
}

.about-eq-paragraph,
.artist-statement-paragraph {
  font-size: 1.25rem;
  font-family: "Roboto", sans-serif;
  line-height: 1.25;
  color: var(--deepBlue);
  margin: 0 1.5rem;
}

.about-eq-paragraph {
  margin-bottom: 1.5rem;
}

.artist-statement-wrapper {
  display: flex;
  flex-direction: column;
}

.beks-about-image {
  max-width: 100vw;
}

.artist-statement-paragraph {
  margin-top: 1.5rem;
}

@media screen and (min-width: 800px) {
  .about-section-wrapper {
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;
  }

  .about-header {
    margin: 0 0 2rem;
  }

  .about-eq-paragraph {
    margin: 0 0 1.5rem;
  }

  .artist-statement-wrapper {
    flex-direction: row;
    align-items: flex-start;
    margin: 2rem 0 4rem;
  }

  .artist-statement-paragraph {
    margin-top: 0rem;
    margin-bottom: 1.5rem;
  }

  .beks-about-image {
    max-height: 30vw;
    max-width: 30vw;
    position: relative;
    top: 0.5rem;
  }
}
