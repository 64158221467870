.instagram-section {
  width: 100%;
  margin-top: 3rem;
}

.instagram-feed-wrapper {
  margin: 2rem 1.5rem;
}

.ig-eq-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  background-color: var(--deepBlue);
  justify-content: center;
  cursor: pointer;
}

.ig-eq-whimsical-artwork {
  width: 85%;
}

.ig-eq-heading-logo {
  width: 10%;
  margin-right: 0.5rem;
}

.ig-eq-heading-text {
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
}

.direct-to-ig-btn {
  width: 100%;
  background-color: var(--deepBlue);
  border: solid 2px var(--deepBlue);
  font-family: "Roboto", sans-serif;
  color: var(--standardWhite);
  text-align: center;
  margin: 2rem 0;
  line-height: 2;
  padding: 5px;
  cursor: pointer;
}

.direct-to-ig-btn:hover {
  color: var(--brightYellow);
}

.direct-to-ig-link {
  text-decoration: none;
  color: var(--standardWhite);
}

.direct-to-ig-link:hover {
  color: var(--brightYellow);
}

@media screen and (min-width: 800px) {
  .instagram-section {
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;
  }

  .instagram-feed-wrapper {
    margin: 2rem 0;
  }

  /* individual instagram image */
  ._wPDyp {
    flex-basis: calc(100% / 4 - 10px);
  }
}
