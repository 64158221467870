.signupMain {
    min-height: 80vh;
    padding: 2rem;
    display: flex;
    flex-direction: column;
}

.signupHeading {
    font-family: 'Averia Serif Libre', cursive;
    color: var(--deepBlue);
    margin-bottom: 3rem;
}

.signupFormWrapper {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: solid 1px var(--deepBlue);
    box-shadow: 5px 5px 3px #888888, 1em 1.5em var(--warmOrange);
}

.signupForm {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
}

.preferredNameHeading, .emailHeading, .passwordHeading {
    font-family: 'Averia Serif Libre', cursive;
    color: var(--deepBlue);
    font-size: 1.5rem;
}

.emailHeading, .passwordHeading {
    margin-top: 2rem;
}

.passwordHeadingWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

#eye {
    background-color: inherit;
    border: none;
    transform: scale(1.5);
}

#eye:hover {
    cursor: pointer;
}

.signupForm input {
    min-width: 15rem;
    margin-top: .75rem;
    font-family: 'Roboto', sans-serif;
    font-size: 1.25rem;
    padding: 5px;
}

.signupForm ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-size: 1rem;
    line-height: 1.25rem;
    max-height: 4rem;
    margin-top: .5rem
}

.createUserBtn {
    background-color: var(--deepBlue);
    color: var(--standardWhite);
    border: solid 1px var(--deepBlue);
    border-radius: 0px;
    font-size: 1rem;
    font-weight: bold;
    margin-top: 3rem;
    height: 2rem;
    cursor: pointer;
}

.createUserBtn:hover {
    color: var(--brightYellow);
}

@media screen and (min-width: 900px) {
    .signupFormWrapper {
        max-width: 50%;
    }

    .signupHeading { margin: 0 0 3rem 23.5vw; }
}