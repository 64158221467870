.singleMerchComponentWrapper {
  display: flex;
  flex-direction: column;
}

.merchDetailsFlexContainer {
  display: flex;
  flex-direction: column;
}

.singleMerch {
  /* margin: 2rem auto 0; */
  margin: auto;
  width: 18rem;
  height: 18rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: black;
  /* box-shadow: 5px 5px 3px #888888; */
  cursor: pointer;
}

.merchAndCandidMerchWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-width: 18rem;
  margin: 1rem auto 0;
}

.smallSingleMerch,
.smallCandidMerch {
  width: 7rem;
  height: 7rem;
  background-size: cover;
  background-color: black;
  cursor: pointer;
}

.singleMerchName {
  margin: 2rem auto 2rem;
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
  text-align: center;
}

.singleMerchPrice {
  margin-bottom: 1rem;
  text-align: center;
  font-family: "Roboto", sans-serif;
  color: var(--deepBlue);
  font-size: 1.5rem;
}

/*
    Select Size Button
*/

.dropbtn {
  background-color: var(--deepBlue);
  border: solid 2px var(--standardWhite);
  padding: 8px;
  font-size: 1.25rem;
  font-weight: 600;
  min-width: 10rem;
}

.isCenteredDropdown {
  display: flex;
  width: 100%;
}

.sizeDropdown {
  position: relative;
  display: inline-block;
  margin: 0 auto 1rem;
}

.dropdownContent {
  position: absolute;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 10rem;
  cursor: pointer;
}

.priceList {
  width: inherit;
}

.priceList li {
  padding: 8px;
  width: 9rem;
  background-color: black;
  color: white;
}

.priceList li:hover {
  background-color: rgb(43, 244, 223);
  color: black;
  font-weight: bold;
}

.sizeDropdown:hover .dropbtn {
  background-color: rgb(222, 240, 250);
}

/* 
    End Select Size Button
*/

/* 
    Quantity Selector
*/

.merchQuantityCounter {
  display: flex;
  flex-direction: row;
  margin: 2rem auto 3rem;
  justify-content: center;
}

.decreaseMerchQuantityBtn,
.increaseMerchQuantityBtn {
  width: 3rem;
  height: 2rem;
  border: solid 2px var(--deepBlue);
  background-color: var(--deepBlue);
  color: white;
  font-family: monospace;
  font-size: 1rem;
  font-weight: 900;
  cursor: pointer;
}

.decreaseMerchQuantityBtn:hover,
.increaseMerchQuantityBtn:hover {
  background-color: var(--hoverDeepBlue);
}

.merchQuantityInput {
  max-width: 3rem;
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-family: "Averia Serif Libre", cursive;
  font-weight: 700;
  text-align: center;
  border-radius: 0;
  position: relative;
  bottom: 1px;
  border: none;
  border-top: solid 1px var(--deepBlue);
  border-bottom: solid 1px var(--deepBlue);
}
/* 
    End Quantity Selector
*/
.singleMerchDesc {
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  color: var(--deepBlue);
  margin: 0 9vw 3rem;
}

.addToCartBtn {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 1rem auto 2rem;
  width: 80vw;
  line-height: 2;
  color: white;
  padding: 5px;
  cursor: pointer;
  color: var(--deepBlue) !important;
  border: none !important;
}

.addToCartBtn:hover {
  background-color: #eeca00;
}

.addToCartBtn:active {
  background-color: white;
  color: black;
  border: solid 2px black;
}

.returnToHomeBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem auto 5rem;
  width: 80vw;
  line-height: 2;
  border: solid 2px black;
  padding: 5px;
  cursor: pointer;
}

.returnToHomeBtn:hover {
  background-color: gray;
  color: white;
}

.returnToHomeBtn:active {
  background-color: gray;
  color: white;
}

@media screen and (min-width: 900px) {
  .merchDetailsFlexContainer {
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    margin: 0 auto 3rem;
  }

  .singleMerch {
    width: 25rem;
    height: 25rem;
  }

  .singleMerch:hover {
    transform: scale(1.015);
  }

  .merchImage {
    width: 130%;
  }

  .merchLogistics {
    width: 70%;
  }

  .singleMerchName,
  .singleMerchPrice {
    text-align: start;
  }

  .singleMerchName {
    font-size: 2.5rem;
    margin-bottom: 8rem;
  }

  .singleMerchPrice {
    font-weight: 400;
  }

  .isCenteredDropdown {
    display: block !important;
  }

  .merchQuantityInput {
    position: initial;
  }

  .merchQuantityCounter {
    justify-content: start;
  }

  .singleMerchDesc {
    text-align: center;
  }

  .addToCartBtn {
    margin: 1rem 0 0;
    width: 15rem;
  }
}
