@import url(https://fonts.googleapis.com/css2?family=Averia+Serif+Libre:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
/* font-family: 'Averia Serif Libre', cursive; */
/* font-family: 'Roboto', sans-serif; */


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Added for fluid typography between viewports */
  font-size: clamp(100%, 1rem + 2vw, 24px);
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* CSS reset */

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	/* font-size: 100%;
	font: inherit; */
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* this removes the default counter buttons from input field  (Chrome, Safari, Edge, Opera) */
input[type='number']::-webkit-inner-spin-button, 
input[type='number']::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    margin: 0;
}

/* this removes the default counter buttons from input field  (Firefox) */
input [type=number] {
  -moz-appearance: textfield;
}
.cartMainWrapper {
  min-height: 100vh;
}

.yourCartHeading {
  margin: 3rem 1rem;
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
}

.cart-heading-message {
  font-family: "Roboto", sans-serif;
  color: var(--deepBlue);
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.25;
  max-width: 90vw;
  margin: 0 auto 3rem;
}

.proceedToCheckoutBtn {
  font-family: "Roboto", sans-serif;
  background-color: var(--deepBlue);
  color: var(--standardWhite);
  border: none;
  font-size: 1rem;
  font-weight: bold;
  padding: 10px;
  margin-left: 1rem;
  padding: 1rem;
  cursor: pointer;
}

.proceedToCheckoutBtn:hover {
  color: var(--brightYellow);
  /* background-color: var(--hoverDeepBlue); */
}

.priceTotal {
  margin: 1rem;
  font-family: "Roboto", sans-serif;
  color: var(--deepBlue);
  font-size: 1.5rem;
}

.cartItemsWrapper {
  /* border: solid 1px black; */
  padding: 3rem 0.5rem;
}

.cartItem {
  border-bottom: solid 1px var(--deepBlue);
  padding: 1rem 0.5rem;
  display: flex;
  flex-direction: column;
}

.cartItemRow1 {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.cartItemImage {
  background-color: gray;
  /* border: solid 1px var(--deepBlue); */
  width: 150px;
  height: 150px;
  background-repeat: no-repeat;
  background-size: cover;
}

.cartItemInfo {
  margin-left: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-width: 50%;
}

.cartItemName {
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
  font-size: 1.25rem;
}

.cartClothingPrintName {
  font-style: italic;
}

.cartClothingDescName {
  font-style: normal;
}

.cartItemPrice {
  font-family: "Averia Serif Libre", cursive;
  font-family: "Roboto", sans-serif;
  color: var(--deepBlue);
  font-size: 1.25rem;
  font-weight: 700;
}

/** Print size */

.printSize {
  font-family: "Roboto", sans-serif;
  color: var(--deepBlue);
  font-size: 0.85rem;
}

/* End print size  */

/** Stickers/pins in stock  */
.cartItemInStock {
  font-size: 0.85rem;
  color: rgb(16, 106, 16);
}

.cartItemNotInStock {
  font-size: 0.85rem;
  color: red;
}
/** End stickers/pins in stock  */

.cartItemRow2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.clothingItemRow2 {
  display: flex;
  justify-content: flex-end;
  height: 0;
}

/* 
    Quantity Selector
*/

.quantityCounter {
  /* box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); */
  display: flex;
  align-self: center;
  /* max-height: 2rem; */
}

.quantityInput {
  max-width: 3rem;
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-family: "Averia Serif Libre", cursive;
  font-weight: 700;
  text-align: center;
  border-radius: 0;
  position: relative;
  bottom: 1px;
  border: none;
  border-top: solid 1px var(--deepBlue);
  border-bottom: solid 1px var(--deepBlue);
}

.decreaseQuantityBtn,
.increaseQuantityBtn {
  width: 3rem;
  height: 2rem;
  border: solid 2px var(--deepBlue);
  background-color: var(--deepBlue);
  color: white;
  font-family: monospace;
  font-size: 1rem;
  font-weight: 900;
  cursor: pointer;
  z-index: 1;
}

.decreaseQuantityBtn:hover,
.increaseQuantityBtn:hover {
  background-color: var(--hoverDeepBlue);
}

/* 
    End Quantity Selector
*/

.deleteItemBtn {
  /* border: solid 1px black; */
  border: none;
  padding: 8px;
  font-family: "Roboto", sans-serif;
  font-size: 1.25rem;
  background-color: var(--deepBlue);
  color: var(--standardWhite);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  cursor: pointer;
  z-index: 1;
}

.deleteItemBtn:hover {
  background-color: var(--hoverDeepBlue);
}

.deleteClothingBtn {
  position: relative;
  bottom: 3rem;
  min-height: 2.6rem;
}

@media screen and (min-width: 900px) {
  .yourCartHeading {
    margin-left: 14.5%;
  }

  .cart-heading-message {
    max-width: 70vw;
  }

  .proceedToCheckoutBtn {
    margin-left: 15%;
  }

  .priceTotal {
    margin-left: 15%;
  }

  .cartItemsWrapper {
    max-width: 70%;
    margin-right: auto;
    margin-left: auto;
  }

  .quantityInput {
    position: static;
    position: initial;
  }
}

.enchantedQuillWrapper {
  margin: 10rem 0 -2rem;
  text-align: center;
  line-height: 1.15;
  font-size: 2rem;
}

.eqLogo {
  cursor: pointer;
  width: 15rem;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: rgb(43, 244, 223); */
  margin: 6rem auto 2rem;
}

.mobileEQlogo {
  margin: 8rem auto 3rem;
}

.navbar {
  padding: 10px 0 0;
  background-color: var(--deepBlue);
  color: white;
  width: 100%;
  position: fixed;
  z-index: 10;
}

.desktopNav {
  /* background-color: rgb(71, 1, 106); */
  background-color: var(--deepBlue);
  display: flex;
  justify-content: space-between;
  padding: 8px;
  position: fixed;
  width: 100%;
  z-index: 10;
}

.desktopNavRightContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 2rem;
}

/* EQ logo */
.navEQbtn {
  background-color: inherit;
  color: white;
  border: none;
  align-self: flex-start;
  position: relative;
  z-index: 1;
}

.navEQ {
  height: 5rem;
  width: 5rem;
  margin-left: 2rem;
  cursor: pointer;
}

.adminNav {
  font-size: 2rem;
  line-height: 2.5;
  margin-left: 2rem;
  cursor: pointer;
  /* margin-top: 1rem; */
}

.desktopNavEQbtn {
  background-color: inherit;
  color: white;
  border: none;
}

.desktopNavEQ {
  font-family: "Averia Serif Libre", cursive;
  font-size: 1.5rem;
  margin-left: 2rem;
  cursor: pointer;
}

/* hamburger icon */
.hamIcon {
  margin-top: 10px;
  margin-right: 2rem;
}

.menuWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  background-color: var(--deepBlue);
  width: 100%;
  top: 15px;
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: end;
  font-size: 1.5rem;
  padding: 15px;
}

.cartIconDiv {
  border: solid 2px white;
  border-radius: 9000px;
  padding: 8px;
  margin: 2rem 0 1rem;
  cursor: pointer;
}

.desktopCartIconDiv {
  cursor: pointer;
  padding: 8px;
  border-radius: 200px;
}

.cartIconDiv:hover {
  background-color: rgb(43, 244, 223);
}

.desktopCartIconDiv:hover {
  background-color: var(--hoverDeepBlue);
}

.cartIconDiv:active {
  background-color: var(--deepBlue);
}

.cartIcon {
  width: 25px;
  height: 25px;
  position: relative;
  top: 5px;
  left: 2px;
}

.desktopCartIcon {
  width: 20px;
  height: 20px;
}

.itemsInCart {
  background-color: var(--warmOrange);
  color: var(--standardWhite);
  font-family: "Courier New", "Courier New", Courier, monospace;
  font-weight: 900;
  font-size: 1rem;
  padding: 0.25rem 0.5rem;
  border-radius: 50px;
  text-align: center;
  position: relative;
  top: -70px;
  left: 10px;
}

.hamburgerItemsInCart {
  /* top: -60px;
  left: 30px; */
  position: absolute;
  display: inline-block;
  z-index: 9999;
  top: 5px;
  left: auto;
  right: 20px;
  /* bottom: -100px; */
}

.desktopItemsInCart {
  background-color: var(--warmOrange);
  color: var(--standardWhite);
  font-family: "Courier New", Courier, monospace;
  font-weight: 900;
  font-size: 0.75rem;
  /* line-height: 1rem; */
  padding: 0.25rem 0.5rem;
  border-radius: 50px;
  text-align: center;
  position: relative;
  top: -15px;
  left: 5px;
}

.menu ul button {
  background-color: inherit;
  border: none;
  font-family: "Averia Serif Libre", cursive;
  color: var(--standardWhite);
  font-size: 1rem;
}

.menu ul li {
  margin: 0.65rem 0;
  /* cursor: pointer; */
}

.menu ul li button:hover {
  background-color: white;
  color: var(--deepBlue);
}

.loginSignupWrapper {
  margin-top: 1rem;
  /* display: flex;
    flex-direction: row; */
}

.desktopLoginSignupWrapper {
  margin-right: 2rem;
  color: white;
}

.loginSignup {
  /* font-family: 'Roboto', sans-serif !important; */
  font-size: 1rem !important;
}

.desktopLoginSignup {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  padding: 8px;
  color: white;
  background-color: inherit;
  border: none;
}

.desktopLogin:hover {
  background-color: orange;
}

.desktopSignup:hover {
  background-color: greenyellow;
  color: var(--deepBlue);
}

.desktopLogout {
  margin-right: 1rem;
}

.desktopLogout:hover {
  background-color: red;
}

.loginSignup:hover {
  background-color: white;
  color: var(--deepBlue);
}

.desktopLinks {
  max-width: 80vw;
  margin: 0 auto 3rem;
  position: relative;
  z-index: 1;
}

.desktopLinksList {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.desktopLinksList li {
  margin-bottom: 3px;
}

/* .desktopLinksList li:first-child:hover { 
    margin-bottom: 0px;
    border-bottom: 3px solid red; 
}

.desktopLinksList li:nth-child(2):hover {
    margin-bottom: 0px; 
    border-bottom: 3px solid orange; 
}

.desktopLinksList li:nth-child(3):hover {
    margin-bottom: 0px; 
    border-bottom: 3px solid yellow; 
}

.desktopLinksList li:nth-child(4):hover { 
    margin-bottom: 0px;
    border-bottom: 3px solid green; 
}

.desktopLinksList li:nth-child(5):hover { 
    margin-bottom: 0px;
    border-bottom: 3px solid blue; 
}

.desktopLinksList li:nth-child(6):hover {
    margin-bottom: 0px;
    border-bottom: 3px solid indigo; 
} */

.desktopLinksList li button {
  border: none;
  background-color: inherit;
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
  /* font-size: 1.5rem; */
  line-height: 1;
  cursor: pointer;
}

.desktopLinksList li button:hover {
  color: var(--warmOrange);
  font-style: italic;
}

/* setting letter-spacing for each button ensures the change in width caused by italicizing doesn't move the other links' position in the row */
#desktopArtPrintsLink:hover {
  letter-spacing: 0.59px;
}
#desktopClothingLink:hover {
  letter-spacing: 1px;
}
#desktopMerchLink:hover {
  letter-spacing: 1px;
}
#desktopCommissionsLink:hover {
  letter-spacing: 1.15px;
}
#desktopAboutLink:hover {
  letter-spacing: 0.9px;
}
#desktopContactLink:hover {
  letter-spacing: 0.9px;
}

.desktopLinksList li button:active {
  color: var(--warmOrange);
  font-style: normal;
  font-weight: 700;
}

@media screen and (min-width: 900px) {
  .enchantedQuillWrapper {
    font-size: 2rem;
    margin: 2rem 0 2rem;
  }

  .desktopLinksList li button {
    font-size: 1.25rem;
  }
}

@media screen and (min-width: 1000px) {
  .desktopLinksList li button {
    font-size: 1.5rem;
  }
}

.heroSection {
  margin-top: -1.5rem;
  display: flex;
  flex-direction: column;
}

.heroImage {
  margin-right: auto;
  margin-left: auto;
  width: 100vw;
  height: 75vw;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}

.heroOverlayText {
  max-width: 75vw;
  margin: 0 auto -7rem;
  position: relative;
  bottom: 50vw;
  cursor: pointer;
}

.artistPickHeading,
.nameHeading,
.priceHeading {
  text-align: center;
  margin: 1rem 0;
  cursor: pointer;
}

.artDescription {
  text-align: center;
  margin: 1rem 1rem 15rem;
  cursor: pointer;
}

@media screen and (min-width: 400px) {
  .heroOverlayText {
    margin: 0 auto -10rem;
  }
}

@media screen and (min-width: 600px) {
  .heroImage {
    /* width: 88.5vw;
        height: 40vw; */
    width: 90vw;
    height: 40vw;
  }

  .heroOverlayText {
    width: 35vw;
    bottom: 28vw;
    margin: 0 auto -8rem;
  }

  .heroOverlayText:hover {
    transform: scale(1.05, 1.05);
  }
}

@media screen and (min-width: 750px) {
  .heroOverlayText {
    margin: 0 auto -10rem;
  }
}

@media screen and (min-width: 900px) {
  .heroOverlayText {
    margin: 0 auto -11rem;
  }
}

@media screen and (min-width: 1100px) {
  .heroOverlayText {
    margin: 0 auto -12rem;
  }
}

@media screen and (min-width: 1300px) {
  .heroOverlayText {
    width: 27.5vw;
    bottom: 25vw;
    margin: 0 auto -12rem;
  }
}

.mostRecentLinksSection {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 3rem 1rem 0;
}

.recentPrintsWrapper, .recentClothingWrapper, .recentMerchItemWrapper, .commissionsWrapper {
    margin-bottom: 1rem;
    width: 50%;
}

.mostRecentLinksImage {
    margin: auto;
    width: 8rem;
    height: 8rem;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
}

.mostRecentLinkName {
    font-family: 'Averia Serif Libre', cursive;
    color: var(--deepBlue);
    text-align: center;
    margin: .5rem auto;
    font-size: 1rem;
}

.homeWelcomeSection {
    margin: 3rem 1.5rem 2rem;
    color: var(--deepBlue);
}

.homeWelcome {
    font-family: 'Averia Serif Libre', cursive;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.homeDescRow1, .homeDescRow2, .homeDescRow3 {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1;
    margin-bottom: 1rem;
}

.welcomeLink:hover {
    color: var(--warmOrange);
    cursor: pointer;
}

@media screen and (min-width: 400px) {
    .mostRecentLinksImage {
        width: 10rem;
        height: 10rem;
    }

    .mostRecentLinkName { font-size: 1rem; }

    .homeWelcome { font-size: 2rem; }

    .homeDescRow1, .homeDescRow2, .homeDescRow3 { font-size: 1.25rem; }
}

@media screen and (min-width: 600) {
    .recentPrintsWrapper, .recentClothingWrapper, .recentMerchItemWrapper, .commissionsWrapper {
        width: 33.33%;
    }
}

@media screen and (min-width: 750px) {
    .recentPrintsWrapper, .recentClothingWrapper, .recentMerchItemWrapper, .commissionsWrapper {
        width: 25%;
    }
}

@media screen and (min-width: 900px) {
    .mostRecentLinksSection {
        margin: 3rem 2rem 0;
    }

    .mostRecentLinksImage {
        width: 12rem;
        height: 12rem;
        /* box-shadow: 5px 5px 3px #888888; */
    }

    .mostRecentLinkName { font-size: 1.25rem; }

    .homeWelcomeSection {
        margin: 5rem 0rem;
        text-align: center;
    }
}

@media screen and (min-width: 1200px) {
    .mostRecentLinksSection {
        justify-content: space-evenly;
    }

    .mostRecentLinksImage {
        width: 15rem;
        height: 15rem;
    }

    .recentPrintsWrapper, .recentClothingWrapper, .recentMerchItemWrapper, .commissionsWrapper {
        width: 20%;
    }
}
.whatsHotSection {
  margin-top: 5rem;
}

.whatsHotHeading {
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
  margin: 1rem 1.25rem 3rem;
  /* font-size: 3rem; */
}

.whatsHotWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 1rem 0.5rem;
}

.whatsHotItemWrapper {
  margin-bottom: 3rem;
  padding-bottom: 15px;
  color: var(--deepBlue);
}

.whatsHotImage {
  margin-right: auto;
  margin-left: auto;
  width: 18rem;
  height: 18rem;
  background-repeat: no-repeat;
  background-size: cover;
  /* box-shadow: 5px 5px 3px #888888; */
}

.whatsHotArtName,
.whatsHotArtPrice {
  text-align: center;
  margin-top: 1.25rem;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 1.25rem auto -10px;
}

.whatsHotClothingPrintName {
  font-style: italic;
}

.whatsHotClothingDescName {
  font-style: normal;
}

.whatsHotArtName {
  font-family: "Averia Serif Libre", cursive;
  font-size: 1rem;
}

.whatsHotArtPrice {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 400;
}

@media screen and (min-width: 400px) {
  .whatsHotSection {
    margin-top: 8rem;
  }

  .whatsHotImage {
    width: 23rem;
    height: 23rem;
  }

  .whatsHotArtName {
    font-size: 1rem;
  }

  .whatsHotArtPrice {
    font-size: 1rem;
  }
}

@media screen and (min-width: 600px) {
  .whatsHotSection {
    margin-top: 5rem;
  }
}

@media screen and (min-width: 900px) {
  .whatsHotItemWrapper:hover {
    transform: scale(1.015);
    box-shadow: 5px 5px 3px #888888;
    cursor: pointer;
  }

  .whatsHotHeading {
    margin: 1rem 0 2rem 4rem;
  }

  .whatsHotImage {
    width: 12rem;
    height: 12rem;
    box-shadow: none;
  }
}

.footer {
  background-color: var(--deepBlue);
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 3rem 0 5rem;
}
.eqFooterLogo {
  width: 10rem;
  height: 10rem;
  margin-bottom: 2rem;
}

.copyrightFooterHeading {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  color: var(--brightYellow);
  font-weight: 400;
  margin-bottom: 2rem;
}

.footerLinksWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  margin: 2rem 0;
}

.footerLink {
  width: 3rem;
  height: 3rem;
  margin: 0 0.75rem;
  cursor: pointer;
}

.footerLinksWrapper a {
  padding: 8px 0;
  border-radius: 8px;
}

.footerLinksWrapper a:hover {
  background-color: var(--hoverDeepBlue);
}

.scrollToTop {
  margin: 2rem auto 0 auto;
  font-family: "Averia Serif Libre", cursive;
  font-size: 1.15rem;
  color: var(--brightYellow);
  line-height: 2;
  width: 12rem;
  border: solid 2px white;
  cursor: pointer;
}

.scrollToTop:hover,
.scrollToTop:active {
  background-color: var(--standardWhite);
  color: var(--deepBlue);
}

@media screen and (min-width: 900px) {
  .copyrightFooterHeading {
    font-size: 1.15rem;
  }
}

.galleryWrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 90%;
}

.galleryHeading {
  margin: 1rem 0 1rem;
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
  font-size: 2rem;
}

.galleryDesc {
  font-family: "Roboto", sans-serif;
  color: var(--deepBlue);
  font-size: 1rem;
  font-weight: 400;
  margin: 1rem 0;
}

.galleryContentWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 3rem 0;
  justify-content: center;
}

.galleryItemWrapper {
  width: 47.5%;
  margin: 0 0.25rem 3rem;
  padding-bottom: 15px;
}

.galleryImage {
  margin: 0 auto;
  height: 9.5rem;
  background-repeat: no-repeat;
  background-size: cover;
  /* box-shadow: 5px 5px 3px #888888; */
}

.galleryArtName,
.galleryArtPrice {
  text-align: center;
  margin-top: 1rem;
  color: var(--deepBlue);
}

.galleryArtPrice {
  margin-top: .5rem;
}

.galleryArtName {
  font-size: 1.15rem;
  font-family: "Averia Serif Libre", cursive;
}

.galleryArtPrice {
  font-size: 1rem;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

@media screen and (min-width: 400px) {
  .galleryItemWrapper {
    margin: 0 0 3rem;
    width: 50%;
  }

  .galleryHeading {
    margin: 3rem 0 1.5rem;
  }

  .galleryImage {
    width: 10rem;
    height: 10rem;
  }
}

@media screen and (min-width: 600px) {
  .galleryItemWrapper {
    width: 33.33%;
  }
}

@media screen and (min-width: 750px) {
  .galleryItemWrapper {
    width: 25%;
  }
}

/* Viewports >= 900px --- tablets & desktop */
@media screen and (min-width: 900px) {
  .galleryWrapper {
    max-width: 80% !important;
  }

  .galleryItemWrapper:hover {
    transform: scale(1.015);
    box-shadow: 5px 5px 3px #888888;
    cursor: pointer;
    /* margin: 0 8rem !important; */
  }

  .galleryHeading {
    margin: 5rem 0 1rem;
  }

  .galleryDesc {
    margin-bottom: 2rem;
  }

  .galleryImage {
    width: 12rem;
    height: 12rem;
    box-shadow: none;
  }

  .galleryArtName,
  .galleryArtPrice {
    max-width: 12rem;
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (min-width: 1200px) {
  .galleryItemWrapper {
    width: 20%;
  }
}

@media screen and (min-width: 1400px) {
  .galleryItemWrapper {
    width: 25%;
    /* width: 15%; */
  }

  .galleryImage {
    width: 16rem;
    height: 16rem;
    box-shadow: none;
  }
}

@media screen and (min-width: 1800px) {
  .galleryWrapper {
    margin-right: 0 !important;
  }

  .galleryContentWrapper {
    max-width: 80%;
    margin: 3rem 0;
  }
}

@media screen and (min-width: 2000px) {
  .galleryContentWrapper {
    margin: 3rem 0;
  }
}

.clothingGalleryDesc {
  font-family: "Roboto", sans-serif;
  color: var(--deepBlue);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  margin: 1rem 1.5rem;
}

.clothingGalleryDescRow2 {
  margin-bottom: 3rem;
}

.galleryClothingItemWrapper {
  width: 100%;
}

.galleryClothingImage {
  width: 90%;
  height: 79vw;
}

.galleryClothingPrintName {
  margin: 1rem 2rem;
  font-style: italic;
  text-align: center;
  color: var(--deepBlue);
  font-size: 1.15rem;
  font-family: "Averia Serif Libre", cursive;
}

.galleryClothingDescName {
  font-style: normal;
  font-weight: 900 !important;
}

.sold-out-wrapper {
  color: var(--deepBlue);
  text-align: center;
}

.sold-out-heading {
  color: var(--warmOrange);
  font-family: "Averia Serif Libre", cursive;
  margin-bottom: 2rem;
}

.sold-out-message {
  color: var(--deepBlue);
  font-family: "Roboto", sans-serif;
  margin-bottom: 2rem;
}

.sold-out-ig-link {
  text-decoration: none;
  color: var(--warmOrange);
}

.sold-out-ig-link:hover {
  font-weight: 600;
}

/* Shimmer load styling */
.loadingItem {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);

  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: rgb(193, 193, 193);
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 1200px 100%;
}

@keyframes shimmer {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}

@media screen and (min-width: 400px) {
  .clothingGalleryDesc {
    margin: 1rem 2rem;
  }
}

@media screen and (min-width: 650px) {
  .galleryClothingItemWrapper {
    max-width: 50%;
  }

  .galleryClothingImage {
    width: 35vw;
    height: 35vw;
  }

  .galleryClothingPrintName {
    max-width: 35vw;
  }

  .clothingGalleryDesc {
    margin: 1rem 2.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .galleryClothingImage {
    width: 30vw;
    height: 30vw;
  }
}

.stickersPinsGalleryDescRow2 {
  margin-bottom: 3rem;
}

.galleryStickersPinsItemWrapper {
  width: 100%;
}

.galleryStickersPinsImage {
  width: 90%;
  height: 79vw;
}

@media screen and (min-width: 650px) {
  .galleryStickersPinsItemWrapper {
    width: 50%;
  }

  .galleryStickersPinsImage {
    width: 35vw;
    height: 35vw;
  }
}

@media screen and (min-width: 1200px) {
  .galleryStickersPinsImage {
    width: 30vw;
    height: 30vw;
  }
}

.about-header {
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
  font-size: 2rem;
  margin: 0 1.5rem 2rem;
}

.about-eq-paragraph,
.artist-statement-paragraph {
  font-size: 1.25rem;
  font-family: "Roboto", sans-serif;
  line-height: 1.25;
  color: var(--deepBlue);
  margin: 0 1.5rem;
}

.about-eq-paragraph {
  margin-bottom: 1.5rem;
}

.artist-statement-wrapper {
  display: flex;
  flex-direction: column;
}

.beks-about-image {
  max-width: 100vw;
}

.artist-statement-paragraph {
  margin-top: 1.5rem;
}

@media screen and (min-width: 800px) {
  .about-section-wrapper {
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;
  }

  .about-header {
    margin: 0 0 2rem;
  }

  .about-eq-paragraph {
    margin: 0 0 1.5rem;
  }

  .artist-statement-wrapper {
    flex-direction: row;
    align-items: flex-start;
    margin: 2rem 0 4rem;
  }

  .artist-statement-paragraph {
    margin-top: 0rem;
    margin-bottom: 1.5rem;
  }

  .beks-about-image {
    max-height: 30vw;
    max-width: 30vw;
    position: relative;
    top: 0.5rem;
  }
}

.styles_instagramItems__13EQe {
  display: flex;
  flex-wrap: wrap;
}
.styles_instagramItem__ansMK {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% / 6 - 10px);
  margin: 5px;
  display: block;
  position: relative;
}
.styles_instagramImg__2Srsz {
  display: block;
  width: 100%;
  height: 100%;
  transition: 0.25s;
  object-fit: cover;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.styles_instagramIcon__2b0X4 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  font-weight: 600;
  font-size: 12px;
  color: #fff;
  opacity: 0;
}
.styles_instagramIcon__2b0X4:hover {
  opacity: 1;
  background-color: #00000061;
}
@media (max-width: 480px) {
  /* CSS */
  .styles_instagramItem__ansMK {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% / 2 - 10px);
    margin: 5px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  /* CSS */
  .styles_instagramItem__ansMK {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% / 3 - 10px);
    margin: 5px;
  }
}

.styles_errorMessage__3XYiC {
  text-align: center;
  color: red;
  font-size: 18px;
}

.instagram-section {
  width: 100%;
  margin-top: 3rem;
}

.instagram-feed-wrapper {
  margin: 2rem 1.5rem;
}

.ig-eq-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  background-color: var(--deepBlue);
  justify-content: center;
  cursor: pointer;
}

.ig-eq-whimsical-artwork {
  width: 85%;
}

.ig-eq-heading-logo {
  width: 10%;
  margin-right: 0.5rem;
}

.ig-eq-heading-text {
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
}

.direct-to-ig-btn {
  width: 100%;
  background-color: var(--deepBlue);
  border: solid 2px var(--deepBlue);
  font-family: "Roboto", sans-serif;
  color: var(--standardWhite);
  text-align: center;
  margin: 2rem 0;
  line-height: 2;
  padding: 5px;
  cursor: pointer;
}

.direct-to-ig-btn:hover {
  color: var(--brightYellow);
}

.direct-to-ig-link {
  text-decoration: none;
  color: var(--standardWhite);
}

.direct-to-ig-link:hover {
  color: var(--brightYellow);
}

@media screen and (min-width: 800px) {
  .instagram-section {
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;
  }

  .instagram-feed-wrapper {
    margin: 2rem 0;
  }

  /* individual instagram image */
  ._wPDyp {
    flex-basis: calc(100% / 4 - 10px);
  }
}

.commissions-header {
  margin: 2rem;
}

.commissions-header-text {
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
  margin-bottom: 1.5rem;
}

.commissions-paragraph {
  font-family: "Roboto", sans-serif;
  color: var(--deepBlue);
  font-size: 1.25rem;
  line-height: 1.15;
  margin-bottom: 1rem;
}

.commissions-strong-paragraph {
  color: var(--warmOrange);
}

.commissions-price-guide-wrapper {
  margin: 2rem auto;
}

.commissions-price-guide-image {
  max-width: 100%;
  width: 100%;
  cursor: pointer;
}

.commissions-price-guide-image:hover {
  transform: scale(1.01);
}

.redirect-to-commissions-form-btn {
  font-family: "Roboto", sans-serif;
  background-color: var(--deepBlue);
  color: var(--standardWhite);
  line-height: 2;
  padding: 5px;
  text-align: center;
  cursor: pointer;
  width: inherit;
  margin: 3rem auto;
  position: relative;
  z-index: 1;
}

.redirect-to-commissions-form-btn:hover {
  color: var(--brightYellow);
}

@media screen and (min-width: 800px) {
  .commissions-header {
    max-width: 80vw;
    margin-left: auto;
    margin-right: auto;
  }
}


.contactWrapper {
    min-height: 80vh;
}

.contactHeading {
    font-family: 'Averia Serif Libre', cursive;
    color: var(--deepBlue);
    font-size: 4rem;
    margin: 3rem 0 1rem;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.contactSubHeading {
    font-family: 'Roboto', sans-serif;
    color: gray;
    /* color: var(--brightYellow); */
    font-size: 1.75rem;
    margin-bottom: 3rem;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.contactForm {
    display: flex;
    flex-direction: column;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.contactNameHeading, .contactEmailHeading, .contactSubjectHeading, .contactMessageHeading {
    font-family: 'Averia Serif Libre', cursive;
    color: var(--deepBlue);
}

.contactForm input {
    margin-top: .7rem;
    margin-bottom: 2.5rem;
    height: 2rem;
    font-family: 'Roboto', sans-serif;
    font-size: 1.5rem;
    line-height: 1;
}

.contactForm .contactMessageInput {
    min-height: 8rem;
    resize: vertical;
    margin-top: .7rem;
    margin-bottom: 2.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-family: 'Roboto', sans-serif;
    padding: 5px;
}

.contactSubmitBtn {
    border: solid 1px var(--deepBlue);
    background-color: var(--deepBlue);
    color: var(--standardWhite);
    margin-bottom: 5rem;
    font-size: 1.5rem;
    padding: .5rem;
    cursor: pointer;
}

.contactSubmitBtn:hover {
    color: var(--brightYellow);
}

@media screen and (min-width: 900px) {
    .contactWrapper {
        max-width: 60vw;
        margin: auto;
    }
}
.loginMain {
  min-height: 80vh;
  padding: 2rem;
  display: flex;
  flex-direction: column;
}

.loginHeading {
  margin-bottom: 3rem;
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
}

.loginFormWrapper {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 1px var(--deepBlue);
  box-shadow: 5px 5px 3px #888888, 1em 1.5em var(--warmOrange);
}

.loginForm {
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
}

.emailLoginHeading,
.passwordLoginHeading {
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
  font-size: 1.5rem;
}

.passwordLoginHeading {
  margin-top: 2rem;
}

.passwordLoginHeadingWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

#loginEye {
  background-color: inherit;
  border: none;
  transform: scale(1.5);
}

#loginEye:hover {
  cursor: pointer;
}

.loginForm input {
  min-width: 15rem;
  margin-top: 0.75rem;
  /* background-color: var(--deepBlue); */
  font-family: "Roboto", sans-serif;
  /* color: var(--warmOrange); */
  font-size: 1.25rem;
  padding: 5px;
}

.loginForm ul {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-size: 1rem;
  line-height: 1.25rem;
  max-height: 4rem;
  margin-top: 0.5rem;
}

.loginBtn {
  background-color: var(--deepBlue);
  font-family: "Roboto", sans-serif;
  color: var(--standardWhite);
  border: solid 1px var(--deepBlue);
  border-radius: 0px;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 3rem;
  height: 2rem;
  cursor: pointer;
}

.loginBtn:hover {
  color: var(--brightYellow);
}

.login-signup-p {
  font-family: "Roboto", sans-serif;
  color: var(--warmOrange);
  font-size: 1rem;
  position: relative;
  top: -15px;
}

.login-signup-a {
  text-decoration: none;
  color: var(--warmOrange);
}

.login-signup-a:hover {
  font-weight: 600;
}

@media screen and (min-width: 900px) {
  .loginFormWrapper {
    max-width: 50%;
  }

  .loginHeading {
    margin: 0 0 3rem 23.5vw;
  }
}

.signupMain {
    min-height: 80vh;
    padding: 2rem;
    display: flex;
    flex-direction: column;
}

.signupHeading {
    font-family: 'Averia Serif Libre', cursive;
    color: var(--deepBlue);
    margin-bottom: 3rem;
}

.signupFormWrapper {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: solid 1px var(--deepBlue);
    box-shadow: 5px 5px 3px #888888, 1em 1.5em var(--warmOrange);
}

.signupForm {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
}

.preferredNameHeading, .emailHeading, .passwordHeading {
    font-family: 'Averia Serif Libre', cursive;
    color: var(--deepBlue);
    font-size: 1.5rem;
}

.emailHeading, .passwordHeading {
    margin-top: 2rem;
}

.passwordHeadingWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

#eye {
    background-color: inherit;
    border: none;
    transform: scale(1.5);
}

#eye:hover {
    cursor: pointer;
}

.signupForm input {
    min-width: 15rem;
    margin-top: .75rem;
    font-family: 'Roboto', sans-serif;
    font-size: 1.25rem;
    padding: 5px;
}

.signupForm ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-size: 1rem;
    line-height: 1.25rem;
    max-height: 4rem;
    margin-top: .5rem
}

.createUserBtn {
    background-color: var(--deepBlue);
    color: var(--standardWhite);
    border: solid 1px var(--deepBlue);
    border-radius: 0px;
    font-size: 1rem;
    font-weight: bold;
    margin-top: 3rem;
    height: 2rem;
    cursor: pointer;
}

.createUserBtn:hover {
    color: var(--brightYellow);
}

@media screen and (min-width: 900px) {
    .signupFormWrapper {
        max-width: 50%;
    }

    .signupHeading { margin: 0 0 3rem 23.5vw; }
}
.singleImageComponentWrapper {
  display: flex;
  flex-direction: column;
}

.printDetailsFlexContainer {
  display: flex;
  flex-direction: column;
}

.singleImage {
  /* margin: 2rem auto 0; */
  margin: auto;
  width: 18rem;
  height: 18rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: black;
  /* box-shadow: 5px 5px 3px #888888; */
  cursor: pointer;
}

.printAndCandidImageWrapper {
  display: flex;
  flex-direction: row;
  justify-content: start;
  max-width: 21rem;
  margin: 1rem auto 0;
  position: relative;
  right: -1.5rem;
}

.smallSingleImage,
.smallCandidImage {
  margin-right: 1rem;
  width: 5.35rem;
  height: 5.35rem;
  background-size: cover;
  background-color: black;
  cursor: pointer;
}

.singleImageName {
  margin: 2rem auto 2rem;
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
  /* font-weight: 400; */
  text-align: center;
}

.singleImagePrice {
  margin-bottom: 1rem;
  text-align: center;
  font-family: "Roboto", sans-serif;
  color: var(--deepBlue);
  font-size: 1.5rem;
}

/*
    Select Size Button
*/

.dropbtn {
  background-color: var(--deepBlue);
  color: var(--standardWhite);
  border: none !important;
  padding: 8px;
  font-family: "Roboto", sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  width: 12rem;
}

.isCenteredDropdown {
  display: flex;
  width: 100%;
}

.sizeDropdown {
  position: relative;
  display: inline-block;
  margin: 0 auto 1rem;
}

.dropdownContent {
  position: absolute;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 100% !important;
  /* margin-left: 2px; */
  margin-top: -2px;
  cursor: pointer;
}

.priceList {
  width: inherit;
}

.priceList li {
  padding: 8px 0 8px 15px;
  width: 11rem !important;
  font-family: "Roboto", sans-serif;
  background-color: var(--deepBlue) !important;
  color: var(--standardWhite);
  font-size: 1.5rem;
}

.priceList li:hover {
  color: var(--brightYellow) !important;
}

.sizeDropdown:hover .dropbtn {
  background-color: var(--hoverDeepBlue) !important;
}

/* 
    End Select Size Button
*/

/* 
    Quantity Selector
*/

.printQuantityCounter {
  display: flex;
  flex-direction: row;
  margin: 2rem auto 3rem;
  justify-content: center;
}

.decreasePrintQuantityBtn,
.increasePrintQuantityBtn {
  width: 3rem;
  height: 2rem;
  border: solid 2px var(--deepBlue);
  background-color: var(--deepBlue);
  color: white;
  font-family: monospace;
  font-size: 1rem;
  font-weight: 900;
  cursor: pointer;
}

.decreasePrintQuantityBtn:hover,
.increasePrintQuantityBtn:hover {
  background-color: var(--hoverDeepBlue);
}

.printQuantityInput {
  max-width: 3rem;
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-family: "Averia Serif Libre", cursive;
  font-weight: 700;
  text-align: center;
  border-radius: 0;
  position: relative;
  bottom: 1px;
  border: none;
  border-top: solid 1px var(--deepBlue);
  border-bottom: solid 1px var(--deepBlue);
}
/* 
    End Quantity Selector
*/

.singleImageDescWrapper {
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  color: var(--deepBlue);
  margin: 0 2rem 3rem;
}

.singleImageDescList li {
  display: list-item;
  list-style-type: disc;
  list-style-position: outside;
  margin-top: 1rem;
  margin-left: 1rem;
}

.addToCartBtn {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin: 1rem auto 2rem;
  width: 80vw;
  line-height: 2;
  /* font-family: 'Averia Serif Libre', cursive; */
  font-family: "Roboto", sans-serif;
  color: var(--standardWhite);
  padding: 5px;
  cursor: pointer;
  background-color: var(--brightYellow);
  color: var(--deepBlue) !important;
  border: none !important;
}

.addToCartBtn:hover {
  background-color: #eeca00 !important;
}

.addToCartBtn:active {
  background-color: var(--standardWhite) !important;
  color: var(--deepBlue) !important;
  /* border: solid 2px black; */
}

.returnToHomeBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem auto 5rem;
  width: 80vw;
  line-height: 2;
  font-family: "Roboto", sans-serif;
  color: var(--deepBlue);
  border: solid 2px var(--deepBlue) !important;
  padding: 5px;
  cursor: pointer;
}

.returnToHomeBtn:hover {
  background-color: var(--deepBlue) !important;
  color: var(--brightYellow) !important;
}

.returnToHomeBtn:active {
  background-color: gray;
  color: white;
}

@media screen and (min-width: 550px) {
  .singleImageDescWrapper {
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 900px) {
  .printDetailsFlexContainer {
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    margin: 0 auto 3rem;
  }

  .singleImage {
    width: 25rem;
    height: 25rem;
  }

  .printAndCandidImageWrapper {
    right: 2rem;
  }

  .printImages {
    width: 130%;
  }

  .printLogistics {
    width: 70%;
  }

  .singleImageName,
  .singleImagePrice {
    text-align: start;
  }

  .singleImageName {
    font-size: 2.5rem;
    margin-bottom: 6rem;
  }

  .singleImagePrice {
    font-weight: 400;
  }

  .sizeDropdown {
    margin: 0 0 2rem;
  }

  .dropdownContent {
    margin-left: 0;
    margin-top: 0;
  }

  .isCenteredDropdown {
    display: block !important;
  }

  .printQuantityCounter {
    justify-content: start;
  }

  .printQuantityInput {
    position: static;
    position: initial;
  }

  .singleImageDescWrapper {
    max-width: 60%;
  }

  .addToCartBtn {
    margin: 1rem 0 0;
    width: 15rem;
  }
}

.singleClothingComponentWrapper {
  display: flex;
  flex-direction: column;
}

.clothingDetailsFlexContainer {
  display: flex;
  flex-direction: column;
}

.singleClothing {
  margin: 2rem auto 0;
  width: 18rem;
  height: 18rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: black;
  box-shadow: 5px 5px 3px #888888;
  cursor: pointer;
}

.clothingAndCandidClothingWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-width: 18rem;
  margin: 1rem auto 0;
}

.smallSingleClothing,
.smallCandidClothing {
  width: 7rem;
  height: 7rem;
  background-size: cover;
  background-color: black;
  cursor: pointer;
}

.singleImageName {
  margin: 2rem auto 2rem;
  /* font-family: "Averia Serif Libre", cursive; */
  color: var(--deepBlue);
  text-align: center;
}

.singleClothingPrintName {
  font-style: italic;
  font-size: 2rem;
  margin: 2rem 1rem 2rem;
}

.singleClothingDescName {
  font-style: normal;
}

.singleClothingPrice {
  margin: 2rem;
  text-align: center;
  font-size: 1.5rem;
}

/*
    Select Size Button
*/

.dropbtn {
  background-color: white;
  border: solid 2px black;
  padding: 8px;
  font-size: 1.25rem;
  font-weight: 600;
  min-width: 10rem;
}

.isCenteredDropdown {
  display: flex;
  width: 100%;
}

.sizeDropdown {
  position: relative;
  display: inline-block;
  margin: 0 auto 1rem;
}

.dropdownContent {
  position: absolute;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 10rem;
  cursor: pointer;
}

.priceList {
  width: inherit;
}

.priceList li {
  padding: 8px;
  width: 9rem;
  background-color: black;
  color: white;
}

.priceList li:hover {
  background-color: rgb(43, 244, 223);
  color: black;
  font-weight: bold;
}

.sizeDropdown:hover .dropbtn {
  background-color: rgb(222, 240, 250);
}

/* 
    End Select Size Button
*/

/* 
    Quantity Selector
*/

.clothingQuantityCounter {
  display: flex;
  flex-direction: row;
  margin: 2rem auto 3rem;
  justify-content: center;
}

.decreaseclothingQuantityBtn,
.increaseclothingQuantityBtn {
  width: 2rem;
  height: 2rem;
  border: solid 1px black;
  background-color: black;
  color: white;
  font-size: 1rem;
  font-weight: 900;
}

.clothingQuantityInput {
  max-width: 3rem;
  font-size: 1rem;
  font-weight: bolder;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-align: center;
  border-radius: 0;
}
/* 
    End Quantity Selector
*/
.singleClothingDesc {
  color: var(--deepBlue);
  font-family: "Roboto", sans-serif;
  margin: 0 9vw 3rem;
  line-height: 1.5;
}

.clothing-disclaimer-wrapper {
  max-width: 80vw;
  margin: 0 9vw 2rem;
}

.clothing-disclaimer {
  color: var(--warmOrange);
  font-size: 1.15rem;
}

.addToCartBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem auto 2rem;
  width: 80vw;
  line-height: 2;
  color: white;
  padding: 5px;
  cursor: pointer;
  background-color: var(--brightYellow) !important;
  color: var(--deepBlue) !important;
  border: none !important;
}

.addToCartBtn:hover {
  background-color: white;
  color: black;
}

.addToCartBtn:active {
  background-color: white;
  color: black;
  border: solid 2px black;
}

.returnToHomeBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem auto 5rem;
  width: 80vw;
  line-height: 2;
  border: solid 2px black;
  padding: 5px;
  cursor: pointer;
}

.returnToHomeBtn:hover {
  background-color: gray;
  color: white;
}

.returnToHomeBtn:active {
  background-color: gray;
  color: white;
}

@media screen and (min-width: 900px) {
  .clothingDetailsFlexContainer {
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    margin: 0 auto 3rem;
  }

  .singleClothing {
    width: 25rem;
    height: 25rem;
  }

  .singleClothing:hover {
    transform: scale(1.015);
  }

  .clothingImages {
    width: 130%;
  }

  .clothingLogistics {
    width: 70%;
  }

  .singleImageName {
    text-align: start;
  }

  .singleClothingPrintName {
    margin: 2rem 2rem 2rem 0;
  }

  .singleClothingPrice {
    font-weight: 400;
  }

  .singleClothingDesc {
    /* text-align: center; */
  }

  .addToCartBtn {
    margin: 1rem 0 0;
    width: 15rem;
  }
}

@media screen and (min-width: 1200px) {
  .singleClothingPrintName {
    margin-right: 8rem;
  }
}

.singleMerchComponentWrapper {
  display: flex;
  flex-direction: column;
}

.merchDetailsFlexContainer {
  display: flex;
  flex-direction: column;
}

.singleMerch {
  /* margin: 2rem auto 0; */
  margin: auto;
  width: 18rem;
  height: 18rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: black;
  /* box-shadow: 5px 5px 3px #888888; */
  cursor: pointer;
}

.merchAndCandidMerchWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-width: 18rem;
  margin: 1rem auto 0;
}

.smallSingleMerch,
.smallCandidMerch {
  width: 7rem;
  height: 7rem;
  background-size: cover;
  background-color: black;
  cursor: pointer;
}

.singleMerchName {
  margin: 2rem auto 2rem;
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
  text-align: center;
}

.singleMerchPrice {
  margin-bottom: 1rem;
  text-align: center;
  font-family: "Roboto", sans-serif;
  color: var(--deepBlue);
  font-size: 1.5rem;
}

/*
    Select Size Button
*/

.dropbtn {
  background-color: var(--deepBlue);
  border: solid 2px var(--standardWhite);
  padding: 8px;
  font-size: 1.25rem;
  font-weight: 600;
  min-width: 10rem;
}

.isCenteredDropdown {
  display: flex;
  width: 100%;
}

.sizeDropdown {
  position: relative;
  display: inline-block;
  margin: 0 auto 1rem;
}

.dropdownContent {
  position: absolute;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 10rem;
  cursor: pointer;
}

.priceList {
  width: inherit;
}

.priceList li {
  padding: 8px;
  width: 9rem;
  background-color: black;
  color: white;
}

.priceList li:hover {
  background-color: rgb(43, 244, 223);
  color: black;
  font-weight: bold;
}

.sizeDropdown:hover .dropbtn {
  background-color: rgb(222, 240, 250);
}

/* 
    End Select Size Button
*/

/* 
    Quantity Selector
*/

.merchQuantityCounter {
  display: flex;
  flex-direction: row;
  margin: 2rem auto 3rem;
  justify-content: center;
}

.decreaseMerchQuantityBtn,
.increaseMerchQuantityBtn {
  width: 3rem;
  height: 2rem;
  border: solid 2px var(--deepBlue);
  background-color: var(--deepBlue);
  color: white;
  font-family: monospace;
  font-size: 1rem;
  font-weight: 900;
  cursor: pointer;
}

.decreaseMerchQuantityBtn:hover,
.increaseMerchQuantityBtn:hover {
  background-color: var(--hoverDeepBlue);
}

.merchQuantityInput {
  max-width: 3rem;
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-family: "Averia Serif Libre", cursive;
  font-weight: 700;
  text-align: center;
  border-radius: 0;
  position: relative;
  bottom: 1px;
  border: none;
  border-top: solid 1px var(--deepBlue);
  border-bottom: solid 1px var(--deepBlue);
}
/* 
    End Quantity Selector
*/
.singleMerchDesc {
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  color: var(--deepBlue);
  margin: 0 9vw 3rem;
}

.addToCartBtn {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 1rem auto 2rem;
  width: 80vw;
  line-height: 2;
  color: white;
  padding: 5px;
  cursor: pointer;
  color: var(--deepBlue) !important;
  border: none !important;
}

.addToCartBtn:hover {
  background-color: #eeca00;
}

.addToCartBtn:active {
  background-color: white;
  color: black;
  border: solid 2px black;
}

.returnToHomeBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem auto 5rem;
  width: 80vw;
  line-height: 2;
  border: solid 2px black;
  padding: 5px;
  cursor: pointer;
}

.returnToHomeBtn:hover {
  background-color: gray;
  color: white;
}

.returnToHomeBtn:active {
  background-color: gray;
  color: white;
}

@media screen and (min-width: 900px) {
  .merchDetailsFlexContainer {
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    margin: 0 auto 3rem;
  }

  .singleMerch {
    width: 25rem;
    height: 25rem;
  }

  .singleMerch:hover {
    transform: scale(1.015);
  }

  .merchImage {
    width: 130%;
  }

  .merchLogistics {
    width: 70%;
  }

  .singleMerchName,
  .singleMerchPrice {
    text-align: start;
  }

  .singleMerchName {
    font-size: 2.5rem;
    margin-bottom: 8rem;
  }

  .singleMerchPrice {
    font-weight: 400;
  }

  .isCenteredDropdown {
    display: block !important;
  }

  .merchQuantityInput {
    position: static;
    position: initial;
  }

  .merchQuantityCounter {
    justify-content: start;
  }

  .singleMerchDesc {
    text-align: center;
  }

  .addToCartBtn {
    margin: 1rem 0 0;
    width: 15rem;
  }
}

.createPrintSection {
  margin: 2rem 2rem 10rem;
}

.createPrintForm {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}

.createPrintForm h1 {
  margin: 2rem 0 1rem;
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
}

.createPrintForm h2 {
  margin: 3rem 0 -1rem;
  font-family: "Averia Serif Libre", cursive;
  font-size: 1.75rem;
  color: var(--deepBlue);
}

.createPrintForm h3 {
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
  font-size: 1rem;
  margin-top: 2rem;
  margin-bottom: 0.25rem;
}

.createPrintForm input {
  max-width: 15rem;
}

.createPrintDescInput {
  max-width: 15rem;
  min-height: 10rem;
}

.createPrintDimensionsCheckboxWrapper {
  display: flex;
  flex-direction: row;
}

.createPrintDimensionsCheckboxWrapper div {
  display: flex;
  justify-content: center;
  margin-right: 2rem;
}

.createPrintDimensionsCheckboxWrapper label {
  font-family: "Roboto", sans-serif;
  color: var(--deepBlue);
  font-size: 1rem;
}

.successfulUploadDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
}

.uploadBtn {
  max-width: 15rem;
  background-color: inherit;
  font-family: "Roboto", sans-serif;
  color: var(--deepBlue);
  font-weight: 600;
  border: solid 1px var(--deepBlue);
  padding: 5px;
  cursor: pointer;
  margin-bottom: 1rem;
}

.uploadBtn:hover {
  background-color: rgb(232, 249, 255);
}

.successfulUploadDiv {
  margin-top: 2rem;
}

.successIcon {
  max-width: 20px;
  max-height: 20px;
}

.successfulUploadDiv h4 {
  font-size: 1rem;
  font-weight: 400;
  margin-left: 0.5rem;
}

.successfulUploadDiv span {
  font-weight: 500;
}

.submitBtn {
  margin-top: 4rem;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  background-color: var(--deepBlue);
  color: var(--standardWhite);
  border: solid 1px var(--deepBlue);
  max-width: 15rem;
  padding: 8px;
  cursor: pointer;
}

.submitBtn:hover {
  background-color: var(--hoverDeepBlue);
}

@media screen and (min-width: 400px) {
  .createPrintForm {
    max-width: 20rem;
    margin: auto;
  }
}

.createClothingDescInput {
  max-width: 15rem;
  min-height: 10rem;
}

.priceInput {
  margin-bottom: 3rem;
}

.createMerchDescInput {
  max-width: 15rem;
  min-height: 10rem;
  font-family: var(--paragraphFont);
  color: var(--deepBlue);
}



.editWrapper {
  max-width: 80%;
  margin: 5rem auto;
}

.editHeading {
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
  font-size: 2rem;
  text-align: center;
  margin-bottom: 2rem;
}

.singleItemEditWrapper {
  margin-bottom: 5rem;
}

.editTextarea {
  resize: none;
  max-width: 15rem;
  max-height: 1.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  padding-left: 0.5rem;
  font-family: var(--paragraphFont);
  color: var(--deepBlue);
}

.deleteWrapper {
  max-width: 80%;
  margin: 5rem auto;
}

.deleteHeading {
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
  font-size: 2rem;
  text-align: center;
  margin-bottom: 2rem;
}

.deleteWrapper {
  max-width: 80%;
  margin: 5rem auto;
}

.deleteHeading {
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
  font-size: 2rem;
  text-align: center;
  margin-bottom: 2rem;
}





.edit-vacation-mode-section {
  margin: 2rem;
}

.edit-vacation-mode-form {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}

.edit-vacation-mode-form h1 {
  margin: 2rem 0 1rem;
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
}

.edit-vacation-mode-form h3 {
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
  font-size: 1rem;
  margin-top: 2rem;
  margin-bottom: 0.25rem;
}

.admin-hint {
  font-family: "Roboto", sans-serif;
  color: var(--deepBlue);
  font-size: 0.7rem;
  margin: 2px 0 1rem;
}

.edit-vacation-mode-checkbox-wrapper {
  display: flex;
  flex-direction: row;
}

.edit-vacation-mode-checkbox-wrapper div {
  display: flex;
  justify-content: center;
  margin-right: 2rem;
}

.edit-vacation-mode-checkbox-wrapper label {
  font-family: "Roboto", sans-serif;
  color: var(--deepBlue);
  font-size: 1rem;
}

.vacation-mode-label {
  font-family: "Roboto", sans-serif;
  color: var(--deepBlue);
  font-size: 0.8rem !important;
  font-weight: 600;
  align-self: center;
}

.edit-vacation-mode-message-textarea {
  max-width: 15rem;
  min-height: 10rem;
}

.submit-btn {
  margin-top: 4rem;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  background-color: var(--deepBlue);
  color: var(--standardWhite);
  border: solid 1px var(--deepBlue);
  max-width: 15rem;
  padding: 8px;
  cursor: pointer;
  z-index: 1;
}

.submit-btn:hover {
  background-color: var(--hoverDeepBlue);
}

@media screen and (min-width: 400px) {
  .edit-vacation-mode-form {
    max-width: 20rem;
    margin: auto;
  }
}

.adminLinksSection {
  margin: 2rem 0 5rem 15vw;
}

.helloBeks {
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
}

.adminCreateLinksHeading,
.adminEditLinksHeading,
.adminDeleteLinksHeading,
.adminWhatsHotLinksHeading,
.adminVacationModeHeading {
  margin-top: 2rem;
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
}

.adminCreateLinks,
.adminEditLinks,
.adminDeleteLinks,
.adminWhatsHotLinks,
.adminVacationModeLink {
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  color: var(--deepBlue);
  position: relative;
  z-index: 1;
}

.adminLink {
  margin-top: 0.75rem;
  cursor: pointer;
}

.adminLink:hover {
  color: var(--warmOrange);
}

.adminBreadcrumbs {
  margin-left: 2rem;
  font-family: "Roboto", sans-serif;
  color: var(--deepBlue);
  font-size: 1rem;
  font-weight: 400;
}

@media screen and (min-width: 900px) {
  .adminBreadcrumbs {
    margin-left: calc(11.5vw * 1.15);
  }
}

.vacation-mode-hero-banner {
  width: 100vw;
}

.vacation-mode-message {
  white-space: pre-wrap;
  color: var(--deepBlue);
  font-family: "Roboto", sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.15;
  text-align: center;
  margin: 3rem 1rem 5rem;
}

@media screen and (min-width: 900px) {
  .vacation-mode-message {
    margin: 5rem auto;
    width: 80%;
  }
}

:root {
  --standardWhite: #eee;
  --deepBlue: #002c50;
  --hoverDeepBlue: #001f39;
  --warmOrange: #f15a22;
  --brightYellow: #fbd63d;
  --titleFont: 'Averia Serif Libre', cursive;
  --paragraphFont: 'Roboto', sans-serif;
}

::selection {
  background-color: #f15a22;
  background-color: var(--warmOrange);
  color: #eee;
  color: var(--standardWhite);
}

button,
input[type='submit'],
input[type='reset'] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.successful-order-section {
  max-width: 90vw;
  display: flex;
  flex-direction: column;
  margin: 3rem auto;
  text-align: center;
}

.success-line-1 {
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
  font-size: 2rem;
  margin-bottom: 2.5rem;
}

.success-line-2 {
  font-family: "Roboto", sans-serif;
  color: var(--deepBlue);
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.15;
  margin-bottom: 4rem;
}

.success-line-3 {
  font-family: "Roboto", sans-serif;
  color: var(--deepBlue);
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.15;
  margin-bottom: 2rem;
}

.success-done-btn {
  border: 0;
  background-color: var(--deepBlue) !important;
  color: var(--standardWhite);
  padding: 8px;
  font-family: "Roboto", sans-serif;
  font-size: 1.15rem;
  margin-bottom: 3rem;
  cursor: pointer;
}

.success-done-btn:hover {
  color: var(--brightYellow);
}

@media screen and (min-width: 900px) {
  .successful-order-section {
    max-width: 60vw;
  }

  .success-line-3 {
    margin-bottom: 4rem;
  }
}

