.cartMainWrapper {
  min-height: 100vh;
}

.yourCartHeading {
  margin: 3rem 1rem;
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
}

.cart-heading-message {
  font-family: "Roboto", sans-serif;
  color: var(--deepBlue);
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.25;
  max-width: 90vw;
  margin: 0 auto 3rem;
}

.proceedToCheckoutBtn {
  font-family: "Roboto", sans-serif;
  background-color: var(--deepBlue);
  color: var(--standardWhite);
  border: none;
  font-size: 1rem;
  font-weight: bold;
  padding: 10px;
  margin-left: 1rem;
  padding: 1rem;
  cursor: pointer;
}

.proceedToCheckoutBtn:hover {
  color: var(--brightYellow);
  /* background-color: var(--hoverDeepBlue); */
}

.priceTotal {
  margin: 1rem;
  font-family: "Roboto", sans-serif;
  color: var(--deepBlue);
  font-size: 1.5rem;
}

.cartItemsWrapper {
  /* border: solid 1px black; */
  padding: 3rem 0.5rem;
}

.cartItem {
  border-bottom: solid 1px var(--deepBlue);
  padding: 1rem 0.5rem;
  display: flex;
  flex-direction: column;
}

.cartItemRow1 {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.cartItemImage {
  background-color: gray;
  /* border: solid 1px var(--deepBlue); */
  width: 150px;
  height: 150px;
  background-repeat: no-repeat;
  background-size: cover;
}

.cartItemInfo {
  margin-left: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-width: 50%;
}

.cartItemName {
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
  font-size: 1.25rem;
}

.cartClothingPrintName {
  font-style: italic;
}

.cartClothingDescName {
  font-style: normal;
}

.cartItemPrice {
  font-family: "Averia Serif Libre", cursive;
  font-family: "Roboto", sans-serif;
  color: var(--deepBlue);
  font-size: 1.25rem;
  font-weight: 700;
}

/** Print size */

.printSize {
  font-family: "Roboto", sans-serif;
  color: var(--deepBlue);
  font-size: 0.85rem;
}

/* End print size  */

/** Stickers/pins in stock  */
.cartItemInStock {
  font-size: 0.85rem;
  color: rgb(16, 106, 16);
}

.cartItemNotInStock {
  font-size: 0.85rem;
  color: red;
}
/** End stickers/pins in stock  */

.cartItemRow2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.clothingItemRow2 {
  display: flex;
  justify-content: flex-end;
  height: 0;
}

/* 
    Quantity Selector
*/

.quantityCounter {
  /* box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); */
  display: flex;
  align-self: center;
  /* max-height: 2rem; */
}

.quantityInput {
  max-width: 3rem;
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-family: "Averia Serif Libre", cursive;
  font-weight: 700;
  text-align: center;
  border-radius: 0;
  position: relative;
  bottom: 1px;
  border: none;
  border-top: solid 1px var(--deepBlue);
  border-bottom: solid 1px var(--deepBlue);
}

.decreaseQuantityBtn,
.increaseQuantityBtn {
  width: 3rem;
  height: 2rem;
  border: solid 2px var(--deepBlue);
  background-color: var(--deepBlue);
  color: white;
  font-family: monospace;
  font-size: 1rem;
  font-weight: 900;
  cursor: pointer;
  z-index: 1;
}

.decreaseQuantityBtn:hover,
.increaseQuantityBtn:hover {
  background-color: var(--hoverDeepBlue);
}

/* 
    End Quantity Selector
*/

.deleteItemBtn {
  /* border: solid 1px black; */
  border: none;
  padding: 8px;
  font-family: "Roboto", sans-serif;
  font-size: 1.25rem;
  background-color: var(--deepBlue);
  color: var(--standardWhite);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  cursor: pointer;
  z-index: 1;
}

.deleteItemBtn:hover {
  background-color: var(--hoverDeepBlue);
}

.deleteClothingBtn {
  position: relative;
  bottom: 3rem;
  min-height: 2.6rem;
}

@media screen and (min-width: 900px) {
  .yourCartHeading {
    margin-left: 14.5%;
  }

  .cart-heading-message {
    max-width: 70vw;
  }

  .proceedToCheckoutBtn {
    margin-left: 15%;
  }

  .priceTotal {
    margin-left: 15%;
  }

  .cartItemsWrapper {
    max-width: 70%;
    margin-right: auto;
    margin-left: auto;
  }

  .quantityInput {
    position: initial;
  }
}
