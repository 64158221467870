.clothingGalleryDesc {
  font-family: "Roboto", sans-serif;
  color: var(--deepBlue);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  margin: 1rem 1.5rem;
}

.clothingGalleryDescRow2 {
  margin-bottom: 3rem;
}

.galleryClothingItemWrapper {
  width: 100%;
}

.galleryClothingImage {
  width: 90%;
  height: 79vw;
}

.galleryClothingPrintName {
  margin: 1rem 2rem;
  font-style: italic;
  text-align: center;
  color: var(--deepBlue);
  font-size: 1.15rem;
  font-family: "Averia Serif Libre", cursive;
}

.galleryClothingDescName {
  font-style: normal;
  font-weight: 900 !important;
}

.sold-out-wrapper {
  color: var(--deepBlue);
  text-align: center;
}

.sold-out-heading {
  color: var(--warmOrange);
  font-family: "Averia Serif Libre", cursive;
  margin-bottom: 2rem;
}

.sold-out-message {
  color: var(--deepBlue);
  font-family: "Roboto", sans-serif;
  margin-bottom: 2rem;
}

.sold-out-ig-link {
  text-decoration: none;
  color: var(--warmOrange);
}

.sold-out-ig-link:hover {
  font-weight: 600;
}

/* Shimmer load styling */
.loadingItem {
  -webkit-box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);

  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: rgb(193, 193, 193);
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 1200px 100%;
}

@-webkit-keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

@keyframes shimmer {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}

@media screen and (min-width: 400px) {
  .clothingGalleryDesc {
    margin: 1rem 2rem;
  }
}

@media screen and (min-width: 650px) {
  .galleryClothingItemWrapper {
    max-width: 50%;
  }

  .galleryClothingImage {
    width: 35vw;
    height: 35vw;
  }

  .galleryClothingPrintName {
    max-width: 35vw;
  }

  .clothingGalleryDesc {
    margin: 1rem 2.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .galleryClothingImage {
    width: 30vw;
    height: 30vw;
  }
}
