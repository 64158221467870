.galleryWrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 90%;
}

.galleryHeading {
  margin: 1rem 0 1rem;
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
  font-size: 2rem;
}

.galleryDesc {
  font-family: "Roboto", sans-serif;
  color: var(--deepBlue);
  font-size: 1rem;
  font-weight: 400;
  margin: 1rem 0;
}

.galleryContentWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 3rem 0;
  justify-content: center;
}

.galleryItemWrapper {
  width: 47.5%;
  margin: 0 0.25rem 3rem;
  padding-bottom: 15px;
}

.galleryImage {
  margin: 0 auto;
  height: 9.5rem;
  background-repeat: no-repeat;
  background-size: cover;
  /* box-shadow: 5px 5px 3px #888888; */
}

.galleryArtName,
.galleryArtPrice {
  text-align: center;
  margin-top: 1rem;
  color: var(--deepBlue);
}

.galleryArtPrice {
  margin-top: .5rem;
}

.galleryArtName {
  font-size: 1.15rem;
  font-family: "Averia Serif Libre", cursive;
}

.galleryArtPrice {
  font-size: 1rem;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

@media screen and (min-width: 400px) {
  .galleryItemWrapper {
    margin: 0 0 3rem;
    width: 50%;
  }

  .galleryHeading {
    margin: 3rem 0 1.5rem;
  }

  .galleryImage {
    width: 10rem;
    height: 10rem;
  }
}

@media screen and (min-width: 600px) {
  .galleryItemWrapper {
    width: 33.33%;
  }
}

@media screen and (min-width: 750px) {
  .galleryItemWrapper {
    width: 25%;
  }
}

/* Viewports >= 900px --- tablets & desktop */
@media screen and (min-width: 900px) {
  .galleryWrapper {
    max-width: 80% !important;
  }

  .galleryItemWrapper:hover {
    transform: scale(1.015);
    box-shadow: 5px 5px 3px #888888;
    cursor: pointer;
    /* margin: 0 8rem !important; */
  }

  .galleryHeading {
    margin: 5rem 0 1rem;
  }

  .galleryDesc {
    margin-bottom: 2rem;
  }

  .galleryImage {
    width: 12rem;
    height: 12rem;
    box-shadow: none;
  }

  .galleryArtName,
  .galleryArtPrice {
    max-width: 12rem;
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (min-width: 1200px) {
  .galleryItemWrapper {
    width: 20%;
  }
}

@media screen and (min-width: 1400px) {
  .galleryItemWrapper {
    width: 25%;
    /* width: 15%; */
  }

  .galleryImage {
    width: 16rem;
    height: 16rem;
    box-shadow: none;
  }
}

@media screen and (min-width: 1800px) {
  .galleryWrapper {
    margin-right: 0 !important;
  }

  .galleryContentWrapper {
    max-width: 80%;
    margin: 3rem 0;
  }
}

@media screen and (min-width: 2000px) {
  .galleryContentWrapper {
    margin: 3rem 0;
  }
}
