.successful-order-section {
  max-width: 90vw;
  display: flex;
  flex-direction: column;
  margin: 3rem auto;
  text-align: center;
}

.success-line-1 {
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
  font-size: 2rem;
  margin-bottom: 2.5rem;
}

.success-line-2 {
  font-family: "Roboto", sans-serif;
  color: var(--deepBlue);
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.15;
  margin-bottom: 4rem;
}

.success-line-3 {
  font-family: "Roboto", sans-serif;
  color: var(--deepBlue);
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.15;
  margin-bottom: 2rem;
}

.success-done-btn {
  border: 0;
  background-color: var(--deepBlue) !important;
  color: var(--standardWhite);
  padding: 8px;
  font-family: "Roboto", sans-serif;
  font-size: 1.15rem;
  margin-bottom: 3rem;
  cursor: pointer;
}

.success-done-btn:hover {
  color: var(--brightYellow);
}

@media screen and (min-width: 900px) {
  .successful-order-section {
    max-width: 60vw;
  }

  .success-line-3 {
    margin-bottom: 4rem;
  }
}
