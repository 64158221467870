.loginMain {
  min-height: 80vh;
  padding: 2rem;
  display: flex;
  flex-direction: column;
}

.loginHeading {
  margin-bottom: 3rem;
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
}

.loginFormWrapper {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 1px var(--deepBlue);
  box-shadow: 5px 5px 3px #888888, 1em 1.5em var(--warmOrange);
}

.loginForm {
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
}

.emailLoginHeading,
.passwordLoginHeading {
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
  font-size: 1.5rem;
}

.passwordLoginHeading {
  margin-top: 2rem;
}

.passwordLoginHeadingWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

#loginEye {
  background-color: inherit;
  border: none;
  transform: scale(1.5);
}

#loginEye:hover {
  cursor: pointer;
}

.loginForm input {
  min-width: 15rem;
  margin-top: 0.75rem;
  /* background-color: var(--deepBlue); */
  font-family: "Roboto", sans-serif;
  /* color: var(--warmOrange); */
  font-size: 1.25rem;
  padding: 5px;
}

.loginForm ul {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-size: 1rem;
  line-height: 1.25rem;
  max-height: 4rem;
  margin-top: 0.5rem;
}

.loginBtn {
  background-color: var(--deepBlue);
  font-family: "Roboto", sans-serif;
  color: var(--standardWhite);
  border: solid 1px var(--deepBlue);
  border-radius: 0px;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 3rem;
  height: 2rem;
  cursor: pointer;
}

.loginBtn:hover {
  color: var(--brightYellow);
}

.login-signup-p {
  font-family: "Roboto", sans-serif;
  color: var(--warmOrange);
  font-size: 1rem;
  position: relative;
  top: -15px;
}

.login-signup-a {
  text-decoration: none;
  color: var(--warmOrange);
}

.login-signup-a:hover {
  font-weight: 600;
}

@media screen and (min-width: 900px) {
  .loginFormWrapper {
    max-width: 50%;
  }

  .loginHeading {
    margin: 0 0 3rem 23.5vw;
  }
}
