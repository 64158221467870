:root {
  --standardWhite: #eee;
  --deepBlue: #002c50;
  --hoverDeepBlue: #001f39;
  --warmOrange: #f15a22;
  --brightYellow: #fbd63d;
  --titleFont: 'Averia Serif Libre', cursive;
  --paragraphFont: 'Roboto', sans-serif;
}

::selection {
  background-color: var(--warmOrange);
  color: var(--standardWhite);
}

button,
input[type='submit'],
input[type='reset'] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
