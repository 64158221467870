.heroSection {
  margin-top: -1.5rem;
  display: flex;
  flex-direction: column;
}

.heroImage {
  margin-right: auto;
  margin-left: auto;
  width: 100vw;
  height: 75vw;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}

.heroOverlayText {
  max-width: 75vw;
  margin: 0 auto -7rem;
  position: relative;
  bottom: 50vw;
  cursor: pointer;
}

.artistPickHeading,
.nameHeading,
.priceHeading {
  text-align: center;
  margin: 1rem 0;
  cursor: pointer;
}

.artDescription {
  text-align: center;
  margin: 1rem 1rem 15rem;
  cursor: pointer;
}

@media screen and (min-width: 400px) {
  .heroOverlayText {
    margin: 0 auto -10rem;
  }
}

@media screen and (min-width: 600px) {
  .heroImage {
    /* width: 88.5vw;
        height: 40vw; */
    width: 90vw;
    height: 40vw;
  }

  .heroOverlayText {
    width: 35vw;
    bottom: 28vw;
    margin: 0 auto -8rem;
  }

  .heroOverlayText:hover {
    transform: scale(1.05, 1.05);
  }
}

@media screen and (min-width: 750px) {
  .heroOverlayText {
    margin: 0 auto -10rem;
  }
}

@media screen and (min-width: 900px) {
  .heroOverlayText {
    margin: 0 auto -11rem;
  }
}

@media screen and (min-width: 1100px) {
  .heroOverlayText {
    margin: 0 auto -12rem;
  }
}

@media screen and (min-width: 1300px) {
  .heroOverlayText {
    width: 27.5vw;
    bottom: 25vw;
    margin: 0 auto -12rem;
  }
}
