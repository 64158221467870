.enchantedQuillWrapper {
  margin: 10rem 0 -2rem;
  text-align: center;
  line-height: 1.15;
  font-size: 2rem;
}

.eqLogo {
  cursor: pointer;
  width: 15rem;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: rgb(43, 244, 223); */
  margin: 6rem auto 2rem;
}

.mobileEQlogo {
  margin: 8rem auto 3rem;
}

.navbar {
  padding: 10px 0 0;
  background-color: var(--deepBlue);
  color: white;
  width: 100%;
  position: fixed;
  z-index: 10;
}

.desktopNav {
  /* background-color: rgb(71, 1, 106); */
  background-color: var(--deepBlue);
  display: flex;
  justify-content: space-between;
  padding: 8px;
  position: fixed;
  width: 100%;
  z-index: 10;
}

.desktopNavRightContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 2rem;
}

/* EQ logo */
.navEQbtn {
  background-color: inherit;
  color: white;
  border: none;
  align-self: flex-start;
  position: relative;
  z-index: 1;
}

.navEQ {
  height: 5rem;
  width: 5rem;
  margin-left: 2rem;
  cursor: pointer;
}

.adminNav {
  font-size: 2rem;
  line-height: 2.5;
  margin-left: 2rem;
  cursor: pointer;
  /* margin-top: 1rem; */
}

.desktopNavEQbtn {
  background-color: inherit;
  color: white;
  border: none;
}

.desktopNavEQ {
  font-family: "Averia Serif Libre", cursive;
  font-size: 1.5rem;
  margin-left: 2rem;
  cursor: pointer;
}

/* hamburger icon */
.hamIcon {
  margin-top: 10px;
  margin-right: 2rem;
}

.menuWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  background-color: var(--deepBlue);
  width: 100%;
  top: 15px;
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: end;
  font-size: 1.5rem;
  padding: 15px;
}

.cartIconDiv {
  border: solid 2px white;
  border-radius: 9000px;
  padding: 8px;
  margin: 2rem 0 1rem;
  cursor: pointer;
}

.desktopCartIconDiv {
  cursor: pointer;
  padding: 8px;
  border-radius: 200px;
}

.cartIconDiv:hover {
  background-color: rgb(43, 244, 223);
}

.desktopCartIconDiv:hover {
  background-color: var(--hoverDeepBlue);
}

.cartIconDiv:active {
  background-color: var(--deepBlue);
}

.cartIcon {
  width: 25px;
  height: 25px;
  position: relative;
  top: 5px;
  left: 2px;
}

.desktopCartIcon {
  width: 20px;
  height: 20px;
}

.itemsInCart {
  background-color: var(--warmOrange);
  color: var(--standardWhite);
  font-family: "Courier New", "Courier New", Courier, monospace;
  font-weight: 900;
  font-size: 1rem;
  padding: 0.25rem 0.5rem;
  border-radius: 50px;
  text-align: center;
  position: relative;
  top: -70px;
  left: 10px;
}

.hamburgerItemsInCart {
  /* top: -60px;
  left: 30px; */
  position: absolute;
  display: inline-block;
  z-index: 9999;
  top: 5px;
  left: auto;
  right: 20px;
  /* bottom: -100px; */
}

.desktopItemsInCart {
  background-color: var(--warmOrange);
  color: var(--standardWhite);
  font-family: "Courier New", Courier, monospace;
  font-weight: 900;
  font-size: 0.75rem;
  /* line-height: 1rem; */
  padding: 0.25rem 0.5rem;
  border-radius: 50px;
  text-align: center;
  position: relative;
  top: -15px;
  left: 5px;
}

.menu ul button {
  background-color: inherit;
  border: none;
  font-family: "Averia Serif Libre", cursive;
  color: var(--standardWhite);
  font-size: 1rem;
}

.menu ul li {
  margin: 0.65rem 0;
  /* cursor: pointer; */
}

.menu ul li button:hover {
  background-color: white;
  color: var(--deepBlue);
}

.loginSignupWrapper {
  margin-top: 1rem;
  /* display: flex;
    flex-direction: row; */
}

.desktopLoginSignupWrapper {
  margin-right: 2rem;
  color: white;
}

.loginSignup {
  /* font-family: 'Roboto', sans-serif !important; */
  font-size: 1rem !important;
}

.desktopLoginSignup {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  padding: 8px;
  color: white;
  background-color: inherit;
  border: none;
}

.desktopLogin:hover {
  background-color: orange;
}

.desktopSignup:hover {
  background-color: greenyellow;
  color: var(--deepBlue);
}

.desktopLogout {
  margin-right: 1rem;
}

.desktopLogout:hover {
  background-color: red;
}

.loginSignup:hover {
  background-color: white;
  color: var(--deepBlue);
}

.desktopLinks {
  max-width: 80vw;
  margin: 0 auto 3rem;
  position: relative;
  z-index: 1;
}

.desktopLinksList {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.desktopLinksList li {
  margin-bottom: 3px;
}

/* .desktopLinksList li:first-child:hover { 
    margin-bottom: 0px;
    border-bottom: 3px solid red; 
}

.desktopLinksList li:nth-child(2):hover {
    margin-bottom: 0px; 
    border-bottom: 3px solid orange; 
}

.desktopLinksList li:nth-child(3):hover {
    margin-bottom: 0px; 
    border-bottom: 3px solid yellow; 
}

.desktopLinksList li:nth-child(4):hover { 
    margin-bottom: 0px;
    border-bottom: 3px solid green; 
}

.desktopLinksList li:nth-child(5):hover { 
    margin-bottom: 0px;
    border-bottom: 3px solid blue; 
}

.desktopLinksList li:nth-child(6):hover {
    margin-bottom: 0px;
    border-bottom: 3px solid indigo; 
} */

.desktopLinksList li button {
  border: none;
  background-color: inherit;
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
  /* font-size: 1.5rem; */
  line-height: 1;
  cursor: pointer;
}

.desktopLinksList li button:hover {
  color: var(--warmOrange);
  font-style: italic;
}

/* setting letter-spacing for each button ensures the change in width caused by italicizing doesn't move the other links' position in the row */
#desktopArtPrintsLink:hover {
  letter-spacing: 0.59px;
}
#desktopClothingLink:hover {
  letter-spacing: 1px;
}
#desktopMerchLink:hover {
  letter-spacing: 1px;
}
#desktopCommissionsLink:hover {
  letter-spacing: 1.15px;
}
#desktopAboutLink:hover {
  letter-spacing: 0.9px;
}
#desktopContactLink:hover {
  letter-spacing: 0.9px;
}

.desktopLinksList li button:active {
  color: var(--warmOrange);
  font-style: normal;
  font-weight: 700;
}

@media screen and (min-width: 900px) {
  .enchantedQuillWrapper {
    font-size: 2rem;
    margin: 2rem 0 2rem;
  }

  .desktopLinksList li button {
    font-size: 1.25rem;
  }
}

@media screen and (min-width: 1000px) {
  .desktopLinksList li button {
    font-size: 1.5rem;
  }
}
