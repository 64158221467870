.adminLinksSection {
  margin: 2rem 0 5rem 15vw;
}

.helloBeks {
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
}

.adminCreateLinksHeading,
.adminEditLinksHeading,
.adminDeleteLinksHeading,
.adminWhatsHotLinksHeading,
.adminVacationModeHeading {
  margin-top: 2rem;
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
}

.adminCreateLinks,
.adminEditLinks,
.adminDeleteLinks,
.adminWhatsHotLinks,
.adminVacationModeLink {
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  color: var(--deepBlue);
  position: relative;
  z-index: 1;
}

.adminLink {
  margin-top: 0.75rem;
  cursor: pointer;
}

.adminLink:hover {
  color: var(--warmOrange);
}

.adminBreadcrumbs {
  margin-left: 2rem;
  font-family: "Roboto", sans-serif;
  color: var(--deepBlue);
  font-size: 1rem;
  font-weight: 400;
}

@media screen and (min-width: 900px) {
  .adminBreadcrumbs {
    margin-left: calc(11.5vw * 1.15);
  }
}
