.whatsHotSection {
  margin-top: 5rem;
}

.whatsHotHeading {
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
  margin: 1rem 1.25rem 3rem;
  /* font-size: 3rem; */
}

.whatsHotWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 1rem 0.5rem;
}

.whatsHotItemWrapper {
  margin-bottom: 3rem;
  padding-bottom: 15px;
  color: var(--deepBlue);
}

.whatsHotImage {
  margin-right: auto;
  margin-left: auto;
  width: 18rem;
  height: 18rem;
  background-repeat: no-repeat;
  background-size: cover;
  /* box-shadow: 5px 5px 3px #888888; */
}

.whatsHotArtName,
.whatsHotArtPrice {
  text-align: center;
  margin-top: 1.25rem;
  max-width: fit-content;
  margin: 1.25rem auto -10px;
}

.whatsHotClothingPrintName {
  font-style: italic;
}

.whatsHotClothingDescName {
  font-style: normal;
}

.whatsHotArtName {
  font-family: "Averia Serif Libre", cursive;
  font-size: 1rem;
}

.whatsHotArtPrice {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 400;
}

@media screen and (min-width: 400px) {
  .whatsHotSection {
    margin-top: 8rem;
  }

  .whatsHotImage {
    width: 23rem;
    height: 23rem;
  }

  .whatsHotArtName {
    font-size: 1rem;
  }

  .whatsHotArtPrice {
    font-size: 1rem;
  }
}

@media screen and (min-width: 600px) {
  .whatsHotSection {
    margin-top: 5rem;
  }
}

@media screen and (min-width: 900px) {
  .whatsHotItemWrapper:hover {
    transform: scale(1.015);
    box-shadow: 5px 5px 3px #888888;
    cursor: pointer;
  }

  .whatsHotHeading {
    margin: 1rem 0 2rem 4rem;
  }

  .whatsHotImage {
    width: 12rem;
    height: 12rem;
    box-shadow: none;
  }
}
