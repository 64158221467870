.stickersPinsGalleryDescRow2 {
  margin-bottom: 3rem;
}

.galleryStickersPinsItemWrapper {
  width: 100%;
}

.galleryStickersPinsImage {
  width: 90%;
  height: 79vw;
}

@media screen and (min-width: 650px) {
  .galleryStickersPinsItemWrapper {
    width: 50%;
  }

  .galleryStickersPinsImage {
    width: 35vw;
    height: 35vw;
  }
}

@media screen and (min-width: 1200px) {
  .galleryStickersPinsImage {
    width: 30vw;
    height: 30vw;
  }
}
