.edit-vacation-mode-section {
  margin: 2rem;
}

.edit-vacation-mode-form {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}

.edit-vacation-mode-form h1 {
  margin: 2rem 0 1rem;
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
}

.edit-vacation-mode-form h3 {
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
  font-size: 1rem;
  margin-top: 2rem;
  margin-bottom: 0.25rem;
}

.admin-hint {
  font-family: "Roboto", sans-serif;
  color: var(--deepBlue);
  font-size: 0.7rem;
  margin: 2px 0 1rem;
}

.edit-vacation-mode-checkbox-wrapper {
  display: flex;
  flex-direction: row;
}

.edit-vacation-mode-checkbox-wrapper div {
  display: flex;
  justify-content: center;
  margin-right: 2rem;
}

.edit-vacation-mode-checkbox-wrapper label {
  font-family: "Roboto", sans-serif;
  color: var(--deepBlue);
  font-size: 1rem;
}

.vacation-mode-label {
  font-family: "Roboto", sans-serif;
  color: var(--deepBlue);
  font-size: 0.8rem !important;
  font-weight: 600;
  align-self: center;
}

.edit-vacation-mode-message-textarea {
  max-width: 15rem;
  min-height: 10rem;
}

.submit-btn {
  margin-top: 4rem;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  background-color: var(--deepBlue);
  color: var(--standardWhite);
  border: solid 1px var(--deepBlue);
  max-width: 15rem;
  padding: 8px;
  cursor: pointer;
  z-index: 1;
}

.submit-btn:hover {
  background-color: var(--hoverDeepBlue);
}

@media screen and (min-width: 400px) {
  .edit-vacation-mode-form {
    max-width: 20rem;
    margin: auto;
  }
}
