.contactWrapper {
    min-height: 80vh;
}

.contactHeading {
    font-family: 'Averia Serif Libre', cursive;
    color: var(--deepBlue);
    font-size: 4rem;
    margin: 3rem 0 1rem;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.contactSubHeading {
    font-family: 'Roboto', sans-serif;
    color: gray;
    /* color: var(--brightYellow); */
    font-size: 1.75rem;
    margin-bottom: 3rem;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.contactForm {
    display: flex;
    flex-direction: column;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.contactNameHeading, .contactEmailHeading, .contactSubjectHeading, .contactMessageHeading {
    font-family: 'Averia Serif Libre', cursive;
    color: var(--deepBlue);
}

.contactForm input {
    margin-top: .7rem;
    margin-bottom: 2.5rem;
    height: 2rem;
    font-family: 'Roboto', sans-serif;
    font-size: 1.5rem;
    line-height: 1;
}

.contactForm .contactMessageInput {
    min-height: 8rem;
    resize: vertical;
    margin-top: .7rem;
    margin-bottom: 2.5rem;
    font-size: 1rem;
    line-height: 1.5;
    font-family: 'Roboto', sans-serif;
    padding: 5px;
}

.contactSubmitBtn {
    border: solid 1px var(--deepBlue);
    background-color: var(--deepBlue);
    color: var(--standardWhite);
    margin-bottom: 5rem;
    font-size: 1.5rem;
    padding: .5rem;
    cursor: pointer;
}

.contactSubmitBtn:hover {
    color: var(--brightYellow);
}

@media screen and (min-width: 900px) {
    .contactWrapper {
        max-width: 60vw;
        margin: auto;
    }
}