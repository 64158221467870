.singleImageComponentWrapper {
  display: flex;
  flex-direction: column;
}

.printDetailsFlexContainer {
  display: flex;
  flex-direction: column;
}

.singleImage {
  /* margin: 2rem auto 0; */
  margin: auto;
  width: 18rem;
  height: 18rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: black;
  /* box-shadow: 5px 5px 3px #888888; */
  cursor: pointer;
}

.printAndCandidImageWrapper {
  display: flex;
  flex-direction: row;
  justify-content: start;
  max-width: 21rem;
  margin: 1rem auto 0;
  position: relative;
  right: -1.5rem;
}

.smallSingleImage,
.smallCandidImage {
  margin-right: 1rem;
  width: 5.35rem;
  height: 5.35rem;
  background-size: cover;
  background-color: black;
  cursor: pointer;
}

.singleImageName {
  margin: 2rem auto 2rem;
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
  /* font-weight: 400; */
  text-align: center;
}

.singleImagePrice {
  margin-bottom: 1rem;
  text-align: center;
  font-family: "Roboto", sans-serif;
  color: var(--deepBlue);
  font-size: 1.5rem;
}

/*
    Select Size Button
*/

.dropbtn {
  background-color: var(--deepBlue);
  color: var(--standardWhite);
  border: none !important;
  padding: 8px;
  font-family: "Roboto", sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  width: 12rem;
}

.isCenteredDropdown {
  display: flex;
  width: 100%;
}

.sizeDropdown {
  position: relative;
  display: inline-block;
  margin: 0 auto 1rem;
}

.dropdownContent {
  position: absolute;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 100% !important;
  /* margin-left: 2px; */
  margin-top: -2px;
  cursor: pointer;
}

.priceList {
  width: inherit;
}

.priceList li {
  padding: 8px 0 8px 15px;
  width: 11rem !important;
  font-family: "Roboto", sans-serif;
  background-color: var(--deepBlue) !important;
  color: var(--standardWhite);
  font-size: 1.5rem;
}

.priceList li:hover {
  color: var(--brightYellow) !important;
}

.sizeDropdown:hover .dropbtn {
  background-color: var(--hoverDeepBlue) !important;
}

/* 
    End Select Size Button
*/

/* 
    Quantity Selector
*/

.printQuantityCounter {
  display: flex;
  flex-direction: row;
  margin: 2rem auto 3rem;
  justify-content: center;
}

.decreasePrintQuantityBtn,
.increasePrintQuantityBtn {
  width: 3rem;
  height: 2rem;
  border: solid 2px var(--deepBlue);
  background-color: var(--deepBlue);
  color: white;
  font-family: monospace;
  font-size: 1rem;
  font-weight: 900;
  cursor: pointer;
}

.decreasePrintQuantityBtn:hover,
.increasePrintQuantityBtn:hover {
  background-color: var(--hoverDeepBlue);
}

.printQuantityInput {
  max-width: 3rem;
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-family: "Averia Serif Libre", cursive;
  font-weight: 700;
  text-align: center;
  border-radius: 0;
  position: relative;
  bottom: 1px;
  border: none;
  border-top: solid 1px var(--deepBlue);
  border-bottom: solid 1px var(--deepBlue);
}
/* 
    End Quantity Selector
*/

.singleImageDescWrapper {
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  color: var(--deepBlue);
  margin: 0 2rem 3rem;
}

.singleImageDescList li {
  display: list-item;
  list-style-type: disc;
  list-style-position: outside;
  margin-top: 1rem;
  margin-left: 1rem;
}

.addToCartBtn {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin: 1rem auto 2rem;
  width: 80vw;
  line-height: 2;
  /* font-family: 'Averia Serif Libre', cursive; */
  font-family: "Roboto", sans-serif;
  color: var(--standardWhite);
  padding: 5px;
  cursor: pointer;
  background-color: var(--brightYellow);
  color: var(--deepBlue) !important;
  border: none !important;
}

.addToCartBtn:hover {
  background-color: #eeca00 !important;
}

.addToCartBtn:active {
  background-color: var(--standardWhite) !important;
  color: var(--deepBlue) !important;
  /* border: solid 2px black; */
}

.returnToHomeBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem auto 5rem;
  width: 80vw;
  line-height: 2;
  font-family: "Roboto", sans-serif;
  color: var(--deepBlue);
  border: solid 2px var(--deepBlue) !important;
  padding: 5px;
  cursor: pointer;
}

.returnToHomeBtn:hover {
  background-color: var(--deepBlue) !important;
  color: var(--brightYellow) !important;
}

.returnToHomeBtn:active {
  background-color: gray;
  color: white;
}

@media screen and (min-width: 550px) {
  .singleImageDescWrapper {
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 900px) {
  .printDetailsFlexContainer {
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    margin: 0 auto 3rem;
  }

  .singleImage {
    width: 25rem;
    height: 25rem;
  }

  .printAndCandidImageWrapper {
    right: 2rem;
  }

  .printImages {
    width: 130%;
  }

  .printLogistics {
    width: 70%;
  }

  .singleImageName,
  .singleImagePrice {
    text-align: start;
  }

  .singleImageName {
    font-size: 2.5rem;
    margin-bottom: 6rem;
  }

  .singleImagePrice {
    font-weight: 400;
  }

  .sizeDropdown {
    margin: 0 0 2rem;
  }

  .dropdownContent {
    margin-left: 0;
    margin-top: 0;
  }

  .isCenteredDropdown {
    display: block !important;
  }

  .printQuantityCounter {
    justify-content: start;
  }

  .printQuantityInput {
    position: initial;
  }

  .singleImageDescWrapper {
    max-width: 60%;
  }

  .addToCartBtn {
    margin: 1rem 0 0;
    width: 15rem;
  }
}
