.footer {
  background-color: var(--deepBlue);
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 3rem 0 5rem;
}
.eqFooterLogo {
  width: 10rem;
  height: 10rem;
  margin-bottom: 2rem;
}

.copyrightFooterHeading {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  color: var(--brightYellow);
  font-weight: 400;
  margin-bottom: 2rem;
}

.footerLinksWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  margin: 2rem 0;
}

.footerLink {
  width: 3rem;
  height: 3rem;
  margin: 0 0.75rem;
  cursor: pointer;
}

.footerLinksWrapper a {
  padding: 8px 0;
  border-radius: 8px;
}

.footerLinksWrapper a:hover {
  background-color: var(--hoverDeepBlue);
}

.scrollToTop {
  margin: 2rem auto 0 auto;
  font-family: "Averia Serif Libre", cursive;
  font-size: 1.15rem;
  color: var(--brightYellow);
  line-height: 2;
  width: 12rem;
  border: solid 2px white;
  cursor: pointer;
}

.scrollToTop:hover,
.scrollToTop:active {
  background-color: var(--standardWhite);
  color: var(--deepBlue);
}

@media screen and (min-width: 900px) {
  .copyrightFooterHeading {
    font-size: 1.15rem;
  }
}
