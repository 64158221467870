.editWrapper {
  max-width: 80%;
  margin: 5rem auto;
}

.editHeading {
  font-family: "Averia Serif Libre", cursive;
  color: var(--deepBlue);
  font-size: 2rem;
  text-align: center;
  margin-bottom: 2rem;
}
